body {
	font-family: var(--font-base);
	font-weight: 300;
	background: var(--color-black);
	color: var(--color-white);

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

.a-main {
	color: var(--color-black);
	background: var(--color-soapstone);
}

.a-renderer {
	position: absolute;
	width: 100%;

	&:nth-child(2) {
		visibility: hidden;
	}
}

.a-scroll {
	background: var(--color-black);

	@include minTablet {
		will-change: transform;
	}

	.page-insights &,
	.page-contact &,
	.page-insight & {
		background: var(--color-soapstone);
	}
}

.a-components {
	position: relative;

	@include maxMobile {
		overflow: hidden;
	}
}

.p-top {
	padding: vw(375, 100) 0 0;

	@include minTablet {
		padding: vw(1440, 190) 0 0;
	}
}

.p-bottom {
	padding: 0 0 vw(375, 100);

	@include minTablet {
		padding: 0 0 vw(1440, 190);
	}
}

.p-top-bottom {
	padding: vw(375, 100) 0;

	@include minTablet {
		padding: vw(1440, 190) 0;
	}
}

.page-home {
	overflow: hidden;
}

.page-insight {
	color: var(--color-black);
}

.page-shows {
	min-height: 100vh;

	@include maxMobile {
		position: fixed;
		inset: 0;
		min-height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
	}
}

.is-dragging {
	cursor: grabbing !important;
}
