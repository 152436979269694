.s-shows-select {
  @include maxMobile {
    padding: vw(375, 100) 0;
  }

  @include minTablet {
    padding: vw(1440, 200) 0;
  }

  .heading-section {
    @include maxMobile {
      margin-bottom: 0;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
  }

  .info {
    @include minTablet {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      pointer-events: none
    }
  }

  .info-block {
    @include minTablet {
      margin: auto vw(1440, 36) vw(1440, 36) 0;
    }
  }

  .container {
    @include minTablet {
      @include container;

      display: flex;
      align-items: center;
      min-height: vw(1440, 600);
      padding: 0 0 vw(1440, 100) 0;
    }
  }

  .show {
    @include minTablet {
      margin: 0 vw(1440, 25);
      padding: vw(1440, 25) 0;
    }

    &:first-child {
      @include minTablet {
        padding-top: 0;
      }
    }

    &:last-child {
      @include maxMobile {
        padding-bottom: vw(375, 80);
      }

      @include minTablet {
        padding-bottom: 0;
      }
    }
  }

  .keyvisual {
    @include minTablet {
      right: 15vw;
    }
  }

  .cta-text {
    margin-bottom: vw(375, 50);

    @include minTablet {
      display: none;
    }
  }

  .button {
    @include minTablet {
      margin: auto;
    }
  }
}
