.s-insights-archive {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  background: var(--color-soapstone);

  @include maxMobile {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .container {
    @include container;

    z-index: 1;
    position: relative;
    margin-bottom: vw(375, 50);

    @include minTablet {
      @include grid;

      margin-bottom: vw(1440, 150);
    }
  }

  .insights-post {
    grid-column: 2/6;

    @include minTablet {
      margin-top: vw(1440, 180);

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(8n + 1),
      &:nth-child(8n + 4),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7) {
        .image {
          height: vw(1440, 357);
        }
      }

      &:nth-child(8n + 2),
      &:nth-child(8n + 3),
      &:nth-child(8n + 5),
      &:nth-child(8n + 8) {
        .image {
          height: vw(1440, 229);
        }
      }

      &:nth-child(8n + 1) {
        grid-column: 2/8;
      }

      &:nth-child(8n + 2) {
        grid-column: 9/13;
        align-self: flex-end;
      }

      &:nth-child(8n + 3) {
        grid-column: 1/5;
      }

      &:nth-child(8n + 4) {
        grid-column: 6/12;
      }

      &:nth-child(8n + 5) {
        grid-column: 2/6;
      }

      &:nth-child(8n + 6) {
        grid-column: 7/13;
      }

      &:nth-child(8n + 7) {
        grid-column: 1/7;
      }

      &:nth-child(8n + 8) {
        grid-column: 8/12;
        align-self: flex-end;
      }
    }
  }
}
