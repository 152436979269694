.s-dragger {
  overflow: hidden;
  user-select: none;

  @include minTablet {
    cursor: grab;
  }

  &[data-dark] {
    background: var(--color-soapstone);
  }

  .container {
    display: inline-flex;
    height: vw(375, 310);

    @include minTablet {
      height: vw(1440, 704);
    }
  }

  .item {
    position: relative;

    &:nth-child(8n + 1) {
      width: vw(375, 274);
      height: vw(375, 183);
      margin-top: vw(375, 23);
      margin-right: vw(375, 54);
      margin-left: vw(375, 20);

      @include minTablet {
        width: vw(1440, 627);
        height: vw(1440, 413);
        margin-top: vw(1440, 57);
        margin-right: vw(1440, 119);
        margin-left: vw(1440, 50);
      }
    }

    &:nth-child(8n + 2) {
      width: vw(375, 152);
      height: vw(375, 102);
      margin-top: vw(375, 174);

      @include minTablet {
        width: vw(1440, 345);
        height: vw(1440, 229);
        margin-top: vw(1440, 398);
      }
    }

    &:nth-child(8n + 3) {
      width: vw(375, 326);
      height: vw(375, 182);
      margin-top: vw(375, 46);
      margin-left: vw(375, -38);
      margin-right: vw(375, 65);

      @include minTablet {
        width: vw(1440, 739);
        height: vw(1440, 415);
        margin-top: vw(1440, 105);
        margin-left: vw(1440, -90);
        margin-right: vw(1440, 129);
      }
    }

    &:nth-child(8n + 4) {
      width: vw(375, 134);
      height: vw(375, 200);
      margin-top: vw(375, 110);
      z-index: 1;

      @include minTablet {
        width: vw(1440, 303);
        height: vw(1440, 456);
        margin-top: vw(1440, 248);
      }
    }

    &:nth-child(8n + 5) {
      width: vw(375, 278);
      height: vw(375, 182);
      margin-left: vw(375, -118);
      margin-right: vw(375, 127);

      @include minTablet {
        width: vw(1440, 630);
        height: vw(1440, 413);
        margin-left: vw(1440, -267);
        margin-right: vw(1440, 127);
      }
    }

    &:nth-child(8n + 6) {
      width: vw(375, 240);
      height: vw(375, 139);
      margin-top: vw(375, 156);

      @include minTablet {
        width: vw(1440, 547);
        height: vw(1440, 311);
        margin-top: vw(1440, 355);
      }
    }

    &:nth-child(8n + 7) {
      width: vw(375, 150);
      height: vw(375, 98);
      margin-top: vw(375, 84);
      margin-left: vw(375, -222);

      @include minTablet {
        width: vw(1440, 341);
        height: vw(1440, 225);
        margin-top: vw(1440, 188);
        margin-left: vw(1440, -505);
      }
    }

    &:nth-child(8n + 8) {
      width: vw(375, 135);
      height: vw(375, 199);
      margin-left: vw(375, 56);

      @include minTablet {
        width: vw(1440, 303);
        height: vw(1440, 452);
        margin-left: vw(1440, 294);
      }
    }

    &:last-child {
      margin-right: vw(375, 20);

      @include minTablet {
        margin-right: vw(1440, 50);
      }
    }
  }
}
