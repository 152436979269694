.s-form {
  background: var(--color-soapstone);

  @include p-bottom;

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .form {
    position: relative;

    @include minTablet {
      grid-column: 4/10;
    }
  }

  .button {
    margin-top: vw(375, 40);

    @include minTablet {
      margin-top: vw(1440, 40);
    }
  }

  .status {
    @include p1;

    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--color-black-04);
  }
}
