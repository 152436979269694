.s-process {
  color: var(--color-black);
  background: var(--color-soapstone);

  .heading-section {
    margin-bottom: vw(375, 25);

    @include minTablet {
      margin-bottom: vw(1440, 55);
    }
  }

  .eye-of-plugged {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      top: vw(1440, -150);
      left: vw(1440, -265);
      width: vw(1440, 530);
      height: vw(1440, 530);
    }

    path {
      fill: var(--color-red);
    }
  }

  .intro {
    @include h6;

    text-align: center;
    margin: 0 vw(375, 20);

    @include minTablet {
      width: vw(1440, 532);
      margin: 0 auto vw(1440, 180);
    }
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;

      position: relative;
      align-items: center;
    }
  }

  .image {
    position: relative;
    border-radius: vw(375, 3);
    overflow: hidden;

    @include maxMobile {
      margin-bottom: vw(375, 20);
    }

    @include minTablet {
      border-radius: vw(1440, 3);
    }
  }

  .content {
    position: relative;
  }

  .digit,
  .heading,
  .text {
    visibility: hidden;
    will-change: transform;
  }

  .digit {
    @include title;

    color: var(--color-red);

    @include minTablet {
      position: absolute;
      top: vw(1440, 7);
      left: vw(1440, -50);
    }
  }

  .heading {
    @include h1;

    white-space: nowrap;
    margin: vw(375, 15) 0;

    @include minTablet {
      margin: 0 0 vw(1440, 15);
    }
  }

  .text {
    @include p1;

    color: var(--color-black-09);
  }

  .bulls-eye {
    position: absolute;
    left: vw(375, 150);
    bottom: vw(375, 400);
    width: vw(375, 98);
    height: vw(375, 98);
    mix-blend-mode: color-dodge;

    @include minTablet {
      left: auto;
      right: vw(1440, -30);
      bottom: vw(1440, 400);
      width: vw(1440, 248);
      height: vw(1440, 248);
    }

    path {
      fill: var(--color-violet);
    }
  }

  .waves {
    position: absolute;
    left: vw(375, -500);
    bottom: vw(375, -250);
    width: vw(375, 1440);
    height: vw(375, 361);
    mix-blend-mode: color-dodge;

    @include minTablet {
      left: auto;
      right: vw(1440, -170);
      bottom: vw(1440, -200);
      width: vw(1440, 1440);
      height: vw(1440, 361);
    }
  }

  .block {
    position: relative;
    padding: vw(375, 35) 0;

    @include minTablet {
      padding: vw(1440, 50) 0;
    }

    &:nth-child(1) {
      padding-top: 0;

      .image {
        height: vw(375, 428);

        @include minTablet {
          grid-column: 3/7;
          height: vw(1440, 438);
        }
      }

      .content {
        @include minTablet {
          grid-column: 8/12;
        }
      }
    }

    &:nth-child(2) {
      .image {
        @include minTablet {
          order: 2;
          grid-column: 7/13;
        }
      }

      .content {
        @include minTablet {
          order: 1;
          grid-column: 2/6;
        }
      }
    }

    &:nth-child(3) {
      padding-bottom: vw(375, 100);

      @include minTablet {
        padding-bottom: vw(1440, 190);
      }

      .image {
        @include minTablet {
          grid-column: 2/9;
          margin-bottom: vw(1440, 85);
        }
      }

      .content {
        @include minTablet {
          grid-column: 4/8;
        }
      }
    }

    &:nth-child(4) {
      color: var(--color-soapstone);
      background: radial-gradient(50% 155% at 65% 50%, #0b0730 0%, #05021d 100%);
      padding: vw(375, 100) 0;
      margin: 0;
      overflow: hidden;

      @include minTablet {
        padding: vw(1440, 190) 0;
      }

      .image {
        &:nth-child(1) {
          @include maxMobile {
            width: vw(375, 276);
            height: vw(375, 156);
          }

          @include minTablet {
            order: 1;
            grid-column: 1/12;
          }
        }
        &:nth-child(2) {
          @include maxMobile {
            width: vw(375, 157);
            height: vw(375, 104);
            margin: vw(375, -50) 0 0 vw(375, 178);
          }

          @include minTablet {
            order: 2;
            grid-column: 8/13;
            margin: vw(1440, -150) 0 0;
          }
        }
      }

      .content {
        @include maxMobile {
          margin-top: vw(375, 50);
        }

        @include minTablet {
          order: 3;
          grid-column: 3/7;
        }
      }

      .title {
        color: var(--color-soapstone);
      }

      .digit {
        color: var(--color-violet);
      }

      .text {
        color: var(--color-soapstone-09);
      }
    }

    &:nth-child(5) {
      margin: 0;

      @include p-top-bottom;

      .image {
        @include minTablet {
          grid-column: 1/7;
        }
      }

      .content {
        @include minTablet {
          grid-column: 8/12;
        }
      }
    }
  }
}
