.s-contact {
  color: var(--color-black);
  background: var(--color-soapstone);

  @include maxMobile {
    padding-bottom: vw(375, 50);
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .block {
    @include maxMobile {
      padding: vw(375, 30) 0;
      border-bottom: 1px solid var(--color-black-02);

      &:first-child {
        padding-top: 0;
      }
    }

    @include minTablet {
      margin-bottom: vw(1440, 100);

      &:nth-child(odd) {
        grid-column: 3/7;
      }

      &:nth-child(even) {
        grid-column: 7/11;
      }
    }
  }

  .title {
    @include title;

    color: var(--color-red);
    margin-bottom: vw(375, 15);

    @include minTablet {
      margin-bottom: vw(1440, 15);
    }
  }

  p,
  li {
    @include h6;
  }

  .button {
    display: inline-flex;
    margin-top: vw(375, 30);
    border-color: var(--color-red);

    @include minTablet {
      margin-top: vw(1440, 30);
    }
  }
}
