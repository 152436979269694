.s-shows-archive {
  min-height: 100vh;
  color: var(--color-soapstone);
  visibility: hidden;

  @include maxMobile {
    position: fixed;
    height: calc(var(--vh, 1vh) * 100);
    inset: 0;
    overflow: scroll;
  }

  .container {
    padding: vw(375, 100) 0 vw(375, 200);

    @include minTablet {
      padding: vw(1440, 150) 0;
    }
  }

  .h2 {
    text-align: center;

    @include maxMobile {
      @include h3;

      margin: 0 auto vw(375, 50);
    }

    @include minTablet {
      @include h1;

      margin: 0 auto vw(1440, 50);
    }
  }

  .section {
    @include container;
  }

  .table {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      display: flex;
      margin-bottom: vw(1440, 15);
    }

    li {
      @include title;

      color: var(--color-soapstone-04);

      &:nth-child(1) {
        width: vw(1440, 189);
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: vw(1440, 284);
      }
    }
  }

  .year {
    position: relative;

    @include maxMobile {
      margin-bottom: vw(375, 25);

      &:last-child {
        margin: 0;
      }
    }

    @include minTablet {
      border-top: 1px solid var(--color-soapstone-02);
    }
  }

  .year-title {
    @include maxMobile {
      @include title;

      color: var(--color-soapstone-04);
    }

    @include minTablet {
      @include h6;

      position: absolute;
      top: vw(1440, 15);
      left: 0;
    }
  }

  .show-link {
    display: block;
    border-bottom: 1px solid var(--color-soapstone-02);
    will-change: transform;
    visibility: hidden;

    @include maxMobile {
      padding: vw(375, 15) 0;
    }

    @include minTablet {
      margin-left: vw(1440, 189);
    }

    &:last-child {
      @include minTablet {
        border: 0;
      }
    }
  }

  .list {
    display: flex;
    align-items: center;

    @include hover {
      &:hover {
        li {
          &:not(.label-exclusive) {
            transform: translateX(vw(1440, 30));
          }
        }
      }
    }

    li {
      @include minTablet {
        &:not(.label-exclusive) {
          transition: transform 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
        }
      }
    }
  }

  .list-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: vw(375, 25);

    @include minTablet {
      padding-right: vw(1440, 25);
    }
  }

  .list-title,
  .list-location {
    @include minTablet {
      width: vw(1440, 284);
    }
  }

  .list-title,
  .list-location,
  .list-category {
    @include h6;

    @include minTablet {
      line-height: vw(1440, 55);
    }
  }

  .list-location,
  .list-category {
    @include maxMobile {
      display: none;
    }
  }

  .label-exclusive {
    margin-left: auto;
  }
}
