.s-image {
  .page-about &,
  .page-insight & {
    color: var(--color-black-09);
    background: var(--color-soapstone);
  }

  .contained {
    @include container;

    position: relative;
  }

  .caption {
    @include p2;

    margin-top: vw(375, 5);

    @include minTablet {
      margin-top: vw(1440, 5);
    }
  }

  .mask {
    -webkit-mask-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
    mask-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
  }

  .element {
    position: absolute;
    width: vw(375, 118);
    height: vw(375, 118);
    mix-blend-mode: color-dodge;
    z-index: 1;

    @include minTablet {
      width: vw(1440, 280);
      height: vw(1440, 280);
    }

    path {
      fill: var(--color-violet);
    }

    &.center-right {
      top: 50%;
      right: vw(375, -59);
      transform: translateY(-50%);

      @include minTablet {
        right: vw(1440, -140);
      }
    }

    &.center-left {
      top: 50%;
      left: vw(375, -59);
      transform: translateY(-50%);

      @include minTablet {
        left: vw(1440, -140);
      }
    }

    &.top-right {
      top: vw(375, -59);
      right: vw(375, 20);

      @include minTablet {
        top: vw(1440, -140);
        right: vw(1440, 90);
      }
    }

    &.bottom-left {
      bottom: vw(375, -59);
      left: vw(375, 20);

      @include minTablet {
        bottom: vw(1440, -140);
        left: vw(1440, 90);
      }
    }
  }
}
