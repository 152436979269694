.s-segments {
  padding: vw(375, 100) 0 0;
  color: var(--color-soapstone);

  @include minTablet {
    padding: vw(1440, 200) 0 0;
  }

  .container {
    @include minTablet {
      @include container;
    }

    @include maxMobile {
      overflow: scroll;
      scroll-snap-type: x mandatory;
      padding: 0 vw(375, 20);

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .wrapper {
    @include maxMobile {
      display: inline-flex;
    }

    @include minTablet {
      @include grid;
    }
  }

  .card {
    position: relative;
    width: vw(375, 276);
    height: vw(375, 386);

    @include maxMobile {
      scroll-snap-align: center;
      margin-right: vw(375, 20);

      &:last-child {
        margin: 0;
      }
    }

    @include minTablet {
      width: vw(1440, 343);
      height: vw(1440, 480);
      align-self: flex-end;
    }

    &:nth-child(1) {
      @include minTablet {
        grid-column: 1/5;
      }
    }

    &:nth-child(2) {
      @include minTablet {
        grid-column: 5/9;
        margin-bottom: vw(1440, 50);
      }
    }

    &:nth-child(3) {
      @include minTablet {
        grid-column: 9/13;
      }
    }
  }

  .heading {
    position: absolute;
    left: vw(375, 20);
    bottom: vw(375, 90);
    z-index: 1;

    @include h4;

    @include minTablet {
      left: vw(1440, 40);
      bottom: vw(1440, 40);
      will-change: transform;
    }

    &::after {
      content: ".";
      color: var(--color-red);
      font-feature-settings: "ss05" on;
    }

    a {
      @include minTablet {
        @include underline;
      }
    }
  }

  .text {
    position: absolute;
    z-index: 1;

    @include maxMobile {
      left: vw(375, 20);
      right: vw(375, 20);
      bottom: vw(375, 0);
    }

    @include minTablet {
      left: vw(1440, 40);
      right: vw(1440, 40);
      bottom: vw(1440, -135);
      visibility: hidden;
      will-change: transform;
    }

    p {
      @include h6;
    }

    ul {
      @include title;

      color: var(--color-violet);

      @include maxMobile {
        display: none;
      }

      @include minTablet {
        margin-top: vw(1440, 15);
      }
    }

    li {
      line-height: vw(375, 24);

      @include minTablet {
        line-height: vw(1440, 24);
      }
    }

    a {
      @include minTablet {
        @include underline;
      }
    }
  }

  .image {
    position: absolute;
    inset: 0;
    overflow: hidden;
    border-radius: vw(375, 3);

    @include maxMobile {
      -webkit-mask-image: linear-gradient(to bottom, black 40%, rgba(0, 0, 0, 0) 100%);
      mask-image: linear-gradient(to bottom, black 40%, rgba(0, 0, 0, 0) 100%);
    }

    @include minTablet {
      border-radius: vw(1440, 3);
      -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    }
  }

  .img {
    @include minTablet {
      will-change: transform;
    }
  }
}
