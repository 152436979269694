.a-logo {
  position: fixed;
  top: vw(375, 20);
  left: vw(375, 20);
  color: var(--color-rich-black);
  transition: color 150ms cubic-bezier(0.25, 0.25, 0.75, 0.75); /* linear */
  z-index: 75;

  path {
    transition: fill 150ms cubic-bezier(0.25, 0.25, 0.75, 0.75); /* linear */
  }

  @include minTablet {
    top: vw(1440, 36);
    left: vw(1440, 36);
  }

  .is-light & {
    color: var(--color-soapstone);
  }

  .wordmark {
    position: absolute;
    top: 0;
    left: 0;
    width: vw(375, 176);
    height: vw(375, 29);
    visibility: hidden;

    @include minTablet {
      width: vw(1440, 176);
      height: vw(1440, 29);
    }
  }

  .monogram {
    width: vw(375, 60);
    height: vw(375, 59);
    visibility: hidden;

    @include minTablet {
      width: vw(1440, 60);
      height: vw(1440, 59);
    }

    path {
      &:last-child {
        fill: var(--color-soapstone);

        .is-light & {
          fill: var(--color-rich-black);
        }
      }
    }
  }
}
