@mixin h1() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h1);
  font-weight: 400;
  line-height: var(--font-lh-h1);
  letter-spacing: var(--font-ls-h1);
}

@mixin h2() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h2);
  font-weight: 400;
  line-height: var(--font-lh-h2);
  letter-spacing: var(--font-ls-h2);
}

@mixin h3() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h3);
  font-weight: 400;
  line-height: var(--font-lh-h3);
  letter-spacing: var(--font-ls-h3);
}

@mixin h4() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h4);
  font-weight: 500;
  line-height: var(--font-lh-h4);
  letter-spacing: var(--font-ls-h4);
}

@mixin h5() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h5);
  font-weight: 400;
  line-height: var(--font-lh-h5);
  letter-spacing: var(--font-ls-h5);
}

@mixin h6() {
  font-family: var(--font-f-eiko);
  font-size: var(--font-s-h6);
  font-weight: 400;
  line-height: var(--font-lh-h6);
  letter-spacing: var(--font-ls-h6);
}

@mixin p1() {
  font-family: var(--font-f-founders);
  font-size: var(--font-s-p1);
  font-weight: 300;
  line-height: var(--font-lh-p1);
  letter-spacing: var(--font-ls-p1);
}

@mixin p2() {
  font-family: var(--font-f-founders);
  font-size: var(--font-s-p2);
  font-weight: 300;
  line-height: var(--font-lh-p2);
  letter-spacing: var(--font-ls-p2);
}

@mixin nav() {
  font-family: var(--font-f-founders);
  font-size: var(--font-s-nav);
  font-weight: 400;
  line-height: var(--font-lh-nav);
  letter-spacing: var(--font-ls-nav);
}

@mixin title() {
  font-family: var(--font-f-founders);
  font-size: var(--font-s-title);
  font-weight: 400;
  line-height: var(--font-lh-title);
  letter-spacing: var(--font-ls-title);
  text-transform: uppercase;
}

@mixin b1() {
  font-family: var(--font-f-founders);
  font-size: var(--font-s-b1);
  font-weight: 400;
  line-height: var(--font-lh-b1);
  letter-spacing: var(--font-ls-b1);
  text-transform: uppercase;
}
