.s-insights-select+.s-whitepaper {
  padding-top: 0;
}

.s-whitepaper {
  color: var(--color-black);
  background: var(--color-soapstone);

  @include maxMobile {
    padding: vw(375, 80) 0 vw(375, 100);
  }

  @include minTablet {
    padding: vw(1440, 100) 0 vw(1440, 190);
  }

  .container {
    @include container;

    position: relative;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  }

  .heading {
    font-family: var(--font-f-eiko);
    font-size: vw(375, 40);
    font-weight: 400;
    text-align: center;
    line-height: vw(375, 44);
    letter-spacing: vw(375, -1.6);
    padding: 0 0 vw(375, 35);

    @include minTablet {
      width: vw(1440, 722);
      font-size: vw(1440, 64);
      line-height: vw(1440, 68);
      letter-spacing: vw(1440, -2.56);
      padding: 0 0 vw(1440, 43);
    }

    i {
      color: var(--color-red);
    }
  }

  .form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include minTablet {
      width: vw(1440, 532);
    }

    .field,
    .checkboxes {
      width: 100%;
    }

    .button {
      margin: vw(375, 35) 0;

      @include minTablet {
        margin: vw(1440, 35) 0;
      }
    }
  }

  .link {
    margin-left: vw(375, 3);

    @include hover {
      @include underline;

      margin-left: vw(1440, 3);

      &::after {
        bottom: vw(1440, 5);
      }
    }
  }

  .status {
    @include p1;

    position: absolute;
    color: var(--color-black-04);

    @include minTablet {
      bottom: vw(1440, -25);
    }
  }
}