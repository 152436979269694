.s-shows {
  color: var(--color-soapstone);

  .h2 {
    @include h3;
  }

  .info {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      z-index: 1;
    }
  }

  .info-block {
    width: vw(1440, 310);
    will-change: transform;
    visibility: hidden;
  }

  .info-title {
    @include title;

    color: var(--color-primary);
    margin-bottom: vw(1440, 10);
    transition: color 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
  }

  .info-text,
  .cta-text {
    @include p1;
  }

  .background {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
    }
  }

  .placeholder {
    display: none;
  }

  .show {
    position: relative;
    user-select: none;

    @include maxMobile {
      padding: vw(375, 50) vw(375, 20);
    }

    @include minTablet {
      cursor: pointer;
    }

    .keyvisual {
      @include minTablet {
        position: absolute;
        width: 1px;
        height: 1px;
        visibility: hidden;
      }
    }

    .button {
      @include minTablet {
        position: absolute;
        inset: 0;
        margin: 0;
        padding: 0;
        text-indent: -999vw;

        > * {
          display: none;
        }
      }
    }

    .sphere {
      @include maxMobile {
        path {
          fill: var(--color-primary);
        }
      }
    }
  }

  .label-exclusive {
    @include maxMobile {
      margin-right: vw(375, 18);
    }
  }

  .content {
    @include maxMobile {
      position: relative;
    }

    @include minTablet {
      display: flex;
      flex-direction: column;
    }
  }

  .head {
    @include minTablet {
      order: 2;
    }
  }

  .heading {
    position: relative;

    @include minTablet {
      margin-bottom: vw(1440, 15);
    }
  }

  .digit {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      @include title;

      position: absolute;
      top: vw(1440, 5);
      left: vw(1440, -50);
    }
  }

  .category {
    @include title;

    color: var(--color-primary);

    @include minTablet {
      display: none;
    }
  }

  .title {
    @include h1;

    @include maxMobile {
      margin: vw(375, 20) 0;
    }

    @include minTablet {
      height: vw(1440, 140);
    }
  }

  .description {
    @include p1;

    color: var(--color-soapstone-09);

    @include maxMobile {
      margin: 0 0 vw(375, 50);
    }

    @include minTablet {
      display: none;
    }
  }

  .keyvisual {
    pointer-events: none;

    @include maxMobile {
      position: relative;
      width: 100%;
      aspect-ratio: 1/1;
    }

    @include minTablet {
      position: absolute;
      width: vw(1440, 720);
      height: vw(1440, 720);
    }
  }

  .cta {
    align-self: center;

    @include maxMobile {
      width: vw(375, 333);
      padding-top: vw(375, 80);
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-top: 1px solid var(--color-soapstone-02);
    }
  }

  .mask {
    position: absolute;
    inset: 0;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: color-dodge;
    will-change: transform;
  }

  .image {
    will-change: transform;
  }
}
