.header-article {
  color: var(--color-black);
  background: var(--color-soapstone);
  padding: vw(375, 125) 0 vw(375, 60);

  @include minTablet {
    padding: vw(1440, 180) 0 vw(1440, 80);
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .column {
    &:nth-child(1) {
      @include minTablet {
        grid-column: 1/8;
      }
    }

    &:nth-child(2) {
      @include minTablet {
        margin-top: auto;
        grid-column: 10/13;
      }
    }
  }

  .title {
    @include title;

    display: flex;
    color: var(--color-red);
    margin-bottom: vw(375, 25);

    @include minTablet {
      margin-bottom: vw(1440, 25);
    }
  }

  .date {
    &::after {
      content: "—";
      padding: 0 vw(375, 5);

      @include minTablet {
        padding: 0 vw(1440, 5);
      }
    }
  }

  .heading {
    @include h2;

    margin-bottom: vw(375, 25);

    @include minTablet {
      margin-bottom: vw(1440, 25);
    }
  }

  .text {
    @include p1;

    color: var(--color-black-09);

    @include maxMobile {
      margin-bottom: vw(375, 25);
    }

    @include minTablet {
      width: vw(1440, 530);
    }
  }
}
