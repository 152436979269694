.form {
  p {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    color: var(--color-rich-black-04);
  }

  .field {
    margin-bottom: vw(375, 25);

    @include minTablet {
      margin-bottom: vw(1440, 25);
    }

    label {
      @include title;

      margin-bottom: vw(375, 10);

      @include minTablet {
        margin-bottom: vw(1440, 10);
      }
    }
  }

  textarea {
    width: 100%;
    height: vw(375, 180);
    padding: vw(375, 20);
    border-radius: vw(375, 3);
    border: 1px solid var(--color-rich-black-02);
    background: none;

    @include p1;

    @include minTablet {
      height: vw(1440, 180);
      padding: vw(1440, 20);
      border-radius: vw(1440, 3);
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    height: vw(375, 60);
    padding: 0 vw(375, 20);
    line-height: vw(375, 60);
    border-radius: vw(375, 3);
    border: 1px solid var(--color-rich-black-02);
    background: none;

    @include p1;

    @include minTablet {
      height: vw(1440, 60);
      padding: 0 vw(1440, 20);
      line-height: vw(1440, 60);
      border-radius: vw(1440, 3);
    }
  }

  input[type="submit"] {
    background: none;
  }
}

.s-sign-up,
.s-sign-in {
  color: var(--color-rich-black);
  background: var(--color-soapstone);
  padding: vw(375, 150) 0;

  @include minTablet {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: vw(1440, 140) 0;
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .column {
    &:nth-child(1) {
      @include maxMobile {
        padding-bottom: vw(375, 55);
        border-bottom: 1px solid var(--color-rich-black-02);
      }

      @include minTablet {
        grid-column: 1/7;
      }
    }

    &:nth-child(2) {
      @include maxMobile {
        padding-top: vw(375, 55);
      }

      @include minTablet {
        grid-column: 7/13;
      }
    }
  }

  .heading {
    @include h1;

    span {
      font-style: italic;
      color: var(--color-red);
    }
  }

  .text {
    @include h6;

    margin-top: vw(375, 25);

    @include minTablet {
      width: vw(1440, 410);
      margin-top: vw(1440, 25);
    }
  }

  .button {
    display: inline-flex;
    margin-top: vw(375, 40);
    border-color: var(--color-red);

    @include minTablet {
      margin-top: vw(1440, 40);
      cursor: pointer;
    }
  }
}

.checkbox {
  input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
  }

  label {
    position: relative;
    user-select: none;
    display: flex;

    @include p2;

    line-height: vw(375, 30);

    @include minTablet {
      cursor: pointer;
      line-height: vw(1440, 30);
    }

    &::before {
      width: vw(375, 30);
      height: vw(375, 30);
      display: block;
      border-radius: vw(375, 3);
      margin-right: vw(375, 15);
      border: 1px solid var(--color-rich-black-02);
      content: "";

      @include minTablet {
        width: vw(1440, 30);
        height: vw(1440, 30);
        border-radius: vw(1440, 3);
        margin-right: vw(1440, 15);
      }
    }
  }

  input:checked ~ label {
    &::after {
      position: absolute;
      top: vw(375, 9);
      left: vw(375, 12);
      width: vw(375, 5);
      height: vw(375, 10);
      border: solid var(--color-red);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: "";

      @include minTablet {
        top: vw(1440, 7);
        left: vw(1440, 12);
        width: vw(1440, 5);
        height: vw(1440, 10);
      }
    }
  }
}

// formspree
.honeypot {
  display: none;
}
