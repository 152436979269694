.s-text-disciplines {
  padding: vw(375, 77) 0 vw(375, 100);
  color: var(--color-black);
  background: var(--color-soapstone);

  @include minTablet {
    padding: 0 0 vw(1440, 190);
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .intro {
    @include maxMobile {
      @include h5;

      margin-bottom: vw(375, 30);
    }

    @include minTablet {
      @include h3;

      grid-column: 1/7;
      text-indent: vw(1440, 60);
      margin-bottom: vw(1440, 30);
    }
  }

  .text {
    color: var(--color-black-09);

    @include minTablet {
      grid-column: 1/6;
    }

    p {
      @include p1;

      margin-bottom: vw(375, 30);

      @include minTablet {
        margin-bottom: vw(1440, 30);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .disciplines {
    @include minTablet {
      grid-column: 8/10;
    }

    li {
      @include p1;

      color: var(--color-black-09);
    }
  }

  .title {
    @include title;

    color: var(--color-red);
    margin-bottom: vw(375, 15);

    @include minTablet {
      margin-bottom: vw(1440, 15);
    }
  }
}
