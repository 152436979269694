.heading-section {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: vw(375, 80);

  @include minTablet {
    margin-bottom: vw(1440, 100);
  }

  .line {
    &:nth-child(1) {
      @include title;

      margin-bottom: vw(375, 15);
      color: var(--color-violet);

      @include minTablet {
        margin-bottom: vw(1440, 15);
      }

      [data-dark] & {
        color: var(--color-red);
      }
    }

    &:nth-child(2) {
      @include h3;
    }
  }

  .graphic {
    position: absolute;
    width: vw(375, 213);
    height: vw(375, 141);
    mix-blend-mode: color-dodge;

    [data-dark] & {
      mix-blend-mode: normal;
    }

    @include minTablet {
      width: vw(1440, 213);
      height: vw(1440, 141);
    }

    path {
      fill: var(--color-violet);
      transform-origin: center;
      transform-box: fill-box;
      visibility: hidden;

      [data-light] & {
        fill: var(--color-blue);
      }

      [data-dark] & {
        fill: var(--color-white);
      }
    }
  }
}
