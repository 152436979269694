@mixin maxMobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin minTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    @content;
  }
}

@mixin p-top {
  padding: vw(375, 100) 0 0;

  @include minTablet {
    padding: vw(1440, 190) 0 0;
  }
}

@mixin p-bottom {
  padding: 0 0 vw(375, 100);

  @include minTablet {
    padding: 0 0 vw(1440, 190);
  }
}

@mixin p-top-bottom {
  padding: vw(375, 100) 0;

  @include minTablet {
    padding: vw(1440, 190) 0;
  }
}

@mixin container {
  margin: 0 vw(375, 20);

  @include minTablet {
    margin: 0 vw(1440, 170);
  }
}

@mixin grid {
  display: grid;
  align-items: flex-start;
  grid-column-gap: vw(375, 20);
  grid-template-columns: repeat(6, 1fr);

  @include minTablet {
    grid-column-gap: vw(1440, 36);
    grid-template-columns: repeat(12, 1fr);
  }
}

@mixin underline {
  position: relative;
  will-change: transform;

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    transform: scaleX(0);
    will-change: transform;
    background: currentColor;
    transform-origin: right center;
    transition: transform 500ms cubic-bezier(1, 0, 0, 1); /* easeInOutExpo */
  }

  @include hover {
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }
}
