:root {
  --color-black: #05021d;
  --color-rich-black: #000000;
  --color-rich-black-02: rgba(0, 0, 0, 0.2);
  --color-rich-black-04: rgba(0, 0, 0, 0.4);
  --color-rich-black-09: rgba(0, 0, 0, 0.9);
  --color-black-rock: #080429;
  --color-dark-purple: #0b0730;
  --color-black-02: rgba(5, 2, 29, 0.2);
  --color-black-04: rgba(5, 2, 29, 0.4);
  --color-black-06: rgba(5, 2, 29, 0.6);
  --color-black-08: rgba(5, 2, 29, 0.8);
  --color-black-09: rgba(5, 2, 29, 0.9);
  --color-white: #ffffff;
  --color-red: #ae0000;
  --color-violet: #9b9dba;
  --color-mystic: #dde7ed;
  --color-soapstone: #ece5db;
  --color-soapstone-02: rgba(236, 229, 219, 0.2);
  --color-soapstone-04: rgba(236, 229, 219, 0.4);
  --color-soapstone-08: rgba(236, 229, 219, 0.8);
  --color-soapstone-09: rgba(236, 229, 219, 0.9);
  --color-blue: #1104d8;
  --color-grey: #f2f1f0;

  --font-base: "Founders Grotesk";
  --font-f-founders: "Founders Grotesk";
  --font-f-eiko: "PP Eiko";

  --font-s-h1: #{vw(375, 40)};
  --font-lh-h1: #{vw(375, 44)};
  --font-ls-h1: #{vw(375, 0.4)};

  --font-s-h2: #{vw(375, 36)};
  --font-lh-h2: #{vw(375, 42)};
  --font-ls-h2: #{vw(375, 0.36)};

  --font-s-h3: #{vw(375, 32)};
  --font-lh-h3: #{vw(375, 40)};
  --font-ls-h3: #{vw(375, 0.32)};

  --font-s-h4: #{vw(375, 24)};
  --font-lh-h4: #{vw(375, 32)};
  --font-ls-h4: #{vw(375, 0.24)};

  --font-s-h5: #{vw(375, 24)};
  --font-lh-h5: #{vw(375, 32)};
  --font-ls-h5: #{vw(375, 0.24)};

  --font-s-h6: #{vw(375, 16)};
  --font-lh-h6: #{vw(375, 24)};
  --font-ls-h6: #{vw(375, 0.16)};

  --font-s-p1: #{vw(375, 16)};
  --font-lh-p1: #{vw(375, 22)};
  --font-ls-p1: #{vw(375, 0.32)};

  --font-s-p2: #{vw(375, 14)};
  --font-lh-p2: #{vw(375, 18)};
  --font-ls-p2: #{vw(375, 0.28)};

  --font-s-title: #{vw(375, 12)};
  --font-lh-title: #{vw(375, 12)};
  --font-ls-title: #{vw(375, 1.68)};

  --font-s-b1: #{vw(375, 14)};
  --font-lh-b1: #{vw(375, 14)};
  --font-ls-b1: #{vw(375, 1.4)};

  @include minTablet {
    --font-s-h1: #{vw(1440, 64)};
    --font-lh-h1: #{vw(1440, 68)};
    --font-ls-h1: #{vw(1440, -2.56)};

    --font-s-h2: #{vw(1440, 40)};
    --font-lh-h2: #{vw(1440, 48)};
    --font-ls-h2: #{vw(1440, -0.8)};

    --font-s-h3: #{vw(1440, 32)};
    --font-lh-h3: #{vw(1440, 40)};
    --font-ls-h3: #{vw(1440, 0.32)};

    --font-s-h4: #{vw(1440, 24)};
    --font-lh-h4: #{vw(1440, 32)};
    --font-ls-h4: #{vw(1440, 0.24)};

    --font-s-h5: #{vw(1440, 24)};
    --font-lh-h5: #{vw(1440, 32)};
    --font-ls-h5: #{vw(1440, 0.24)};

    --font-s-h6: #{vw(1440, 16)};
    --font-lh-h6: #{vw(1440, 24)};
    --font-ls-h6: #{vw(1440, 0.16)};

    --font-s-p1: #{vw(1440, 16)};
    --font-lh-p1: #{vw(1440, 22)};
    --font-ls-p1: #{vw(1440, 0.32)};

    --font-s-p2: #{vw(1440, 14)};
    --font-lh-p2: #{vw(1440, 18)};
    --font-ls-p2: #{vw(1440, 0.28)};

    --font-s-nav: #{vw(1440, 16)};
    --font-lh-nav: #{vw(1440, 22)};
    --font-ls-nav: #{vw(1440, 0.32)};

    --font-s-title: #{vw(1440, 12)};
    --font-lh-title: #{vw(1440, 12)};
    --font-ls-title: #{vw(1440, 1.68)};

    --font-s-b1: #{vw(1440, 14)};
    --font-lh-b1: #{vw(1440, 14)};
    --font-ls-b1: #{vw(1440, 1.4)};
  }
}
