.label-exclusive {
  @include title;

  display: inline-flex;
  padding: vw(375, 10);
  border: 1px solid rgba(236, 229, 219, 0.2);
  border-radius: 4px;

  @include minTablet {
    padding: vw(1440, 10);
  }
}
