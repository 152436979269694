.s-awards {
  padding: vw(375, 100) 0;
  color: var(--color-soapstone);
  background: #0b0730;

  @include minTablet {
    padding: vw(1440, 190) 0;
  }

  .container {
    @include container;

    @include minTablet {
      padding: 0 vw(1440, 95);
    }
  }

  .list {
    display: flex;
    border-bottom: 1px solid var(--color-soapstone-02);

    @include maxMobile {
      display: none;
    }

    li {
      @include title;

      color: var(--color-soapstone-04);

      @include minTablet {
        line-height: vw(1440, 40);
      }
    }
  }

  .link {
    position: relative;

    @include maxMobile {
      display: block;
      margin-bottom: vw(375, 43);
    }

    @include hover {
      &:hover {
        li {
          transform: translateX(vw(1440, 30));
        }

        .arrow {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .award {
    @include maxMobile {
      border-bottom: 1px solid var(--color-soapstone-02);
    }

    @include minTablet {
      display: flex;
      height: vw(1440, 60);
      line-height: vw(1440, 60);
      border-bottom: 1px solid var(--color-soapstone-02);
    }

    li {
      @include minTablet {
        @include h6;

        line-height: vw(1440, 60);
        transition: transform 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
      }
    }
  }

  li {
    &:nth-child(1) {
      @include maxMobile {
        @include title;

        line-height: vw(375, 30);
        color: var(--color-soapstone-04);
        border-bottom: 1px solid var(--color-soapstone-02);
      }

      @include minTablet {
        width: vw(1440, 95);
      }
    }

    &:nth-child(2) {
      @include maxMobile {
        display: inline-block;

        &::after {
          content: " / ";
        }
      }
      @include minTablet {
        width: vw(1440, 284);
      }
    }

    &:nth-child(3) {
      @include maxMobile {
        display: inline-block;
      }
      @include minTablet {
        width: vw(1440, 190);
      }
    }

    &:not(:first-child) {
      @include maxMobile {
        @include h6;

        line-height: vw(375, 40);
      }
    }

    &:last-child {
      @include maxMobile {
        display: none;
      }
    }
  }

  .arrow {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      top: vw(1440, 25);
      right: vw(1440, 25);
      width: vw(1440, 14);
      height: vw(1440, 8);
      opacity: 0;
      transform: translateX(vw(1440, -30));
      transition: all 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
    }
  }

  .table {
    @include minTablet {
      margin-bottom: vw(1440, 100);
    }
  }
}
