.header-text + .s-richtext,
.s-richt-text + .s-richt-text {
  padding-top: 0;
}

.s-richtext {
  padding: vw(375, 50) 0;
  color: var(--color-black-09);
  background: var(--color-soapstone);

  @include minTablet {
    padding: vw(1440, 100) 0;
  }

  .container {
    @include maxMobile {
      @include container;
    }

    @include minTablet {
      width: vw(1440, 722);
      margin: auto;
    }

    strong {
      font-weight: normal;
    }

    a {
      color: var(--color-red);
      text-decoration: underline;
    }

    h2 {
      @include h4;

      margin-bottom: vw(375, 15);

      @include minTablet {
        margin-bottom: vw(1440, 15);
      }
    }

    h3 {
      @include h6;

      margin-bottom: vw(375, 15);

      @include minTablet {
        margin-bottom: vw(1440, 15);
      }
    }

    p + h2 {
      margin-top: vw(375, 60);

      @include minTablet {
        margin-top: vw(1440, 60);
      }
    }

    p,
    li {
      @include p1;

      margin-bottom: vw(375, 30);

      @include minTablet {
        margin-bottom: vw(1440, 30);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    blockquote {
      margin: vw(375, 80) 0;

      @include minTablet {
        margin: vw(1440, 80) 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        @include h2;

        text-align: center;
        margin: 0;
      }
    }

    ul {
      margin-bottom: vw(375, 30);

      @include minTablet {
        margin-bottom: vw(1440, 30);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      position: relative;
      margin: 0 0 vw(375, 5) 0;
      padding-left: vw(375, 20);

      @include minTablet {
        margin: 0 0 vw(1440, 5) 0;
        padding-left: vw(1440, 20);
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 200%;
        line-height: 0.5;
        margin: 0 vw(375, 10) 0 0;
        content: "·";

        @include minTablet {
          margin: 0 vw(1440, 10) 0 0;
        }
      }
    }
  }
}
