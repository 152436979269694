.is-desktop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body,
html {
  @include minTablet {
    overscroll-behavior: none;
  }
}

html {
  scrollbar-width: none;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  list-style: none;
  box-sizing: border-box;
  border-collapse: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  pointer-events: none;
}

img,
video {
  width: 100%;
  height: 100%;
  display: block;
  user-select: none;
  object-fit: cover;
}

svg {
  display: block;
}

button {
  background: 0;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
}

header,
figure,
section,
footer {
  position: relative;
}
