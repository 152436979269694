.s-collage {
  background: var(--color-soapstone);
  overflow: hidden;

  @include maxMobile {
    padding-bottom: vw(375, 70);
  }

  @include minTablet {
    height: vw(1440, 1865);
    padding-bottom: vw(1440, 200);
  }

  .row {
    position: relative;

    @include container;
  }

  .title {
    @include title;

    margin: vw(375, 15) 0;
    color: var(--color-red);

    @include minTablet {
      margin: vw(1440, 25) 0;
    }
  }

  .text {
    text-align: center;
  }

  .media {
    @include minTablet {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .graphic {
    display: block;
    color: var(--color-white);
  }

  .heading {
    @include maxMobile {
      @include h5;
    }

    @include minTablet {
      @include h3;
    }
  }

  .image {
    position: absolute;
    border-radius: vw(375, 3);
    overflow: hidden;

    @include minTablet {
      border-radius: vw(1440, 3);
    }
  }

  .row {
    &:nth-child(1) {
      @include minTablet {
        padding-top: vw(1440, 90);
      }

      .media {
        @include maxMobile {
          height: vw(375, 215);
        }

        @include minTablet {
          height: vw(1440, 425);
        }
      }

      .block {
        @include minTablet {
          width: vw(1440, 437);
          margin: 0 auto 0 vw(1440, 568);
        }
      }

      .graphic {
        width: vw(375, 230);

        @include minTablet {
          width: vw(1440, 367);
        }
      }

      .image {
        &:nth-child(1) {
          @include maxMobile {
            left: vw(375, -80);
            width: vw(375, 265);
            height: vw(375, 160);
          }

          @include minTablet {
            top: 0;
            left: vw(1440, -60);
            width: vw(1440, 524);
            height: vw(1440, 318);
          }
        }

        &:nth-child(2) {
          z-index: 2;

          @include maxMobile {
            top: vw(375, 125);
            left: vw(375, -46);
            width: vw(375, 144);
            height: vw(375, 90);
          }

          @include minTablet {
            top: vw(1440, 210);
            left: vw(1440, -230);
            width: vw(1440, 342);
            height: vw(1440, 214);
          }
        }

        &:nth-child(3) {
          @include maxMobile {
            top: vw(375, 71);
            right: vw(375, -74);
            width: vw(375, 144);
            height: vw(375, 96);
          }

          @include minTablet {
            top: vw(1440, 166);
            right: vw(1440, -340);
            width: vw(1440, 342);
            height: vw(1440, 228);
          }
        }
      }
    }

    &:nth-child(2) {
      @include maxMobile {
        margin-top: vw(375, 75);
      }

      .media {
        @include maxMobile {
          height: vw(375, 205);
          margin-bottom: vw(375, 80);
        }

        @include minTablet {
          top: vw(1440, 155);
          height: vw(1440, 908);
        }
      }

      .block {
        @include minTablet {
          width: vw(1440, 532);
          margin: 0 auto 0 0;
        }
      }

      .graphic {
        width: vw(375, 180);

        @include minTablet {
          width: vw(1440, 252);
        }
      }

      .image {
        &:nth-child(1) {
          z-index: 2;

          @include maxMobile {
            top: 0;
            left: vw(375, 32);
            width: vw(375, 154);
            height: vw(375, 103);
          }

          @include minTablet {
            top: vw(1440, 0);
            left: vw(1440, 699);
            width: vw(1440, 342);
            height: vw(1440, 229);
          }
        }

        &:nth-child(2) {
          @include maxMobile {
            top: vw(375, 73);
            left: vw(375, 122);
            width: vw(375, 235);
            height: vw(375, 132);
          }

          @include minTablet {
            top: vw(1440, 160);
            left: vw(1440, 896);
            width: vw(1440, 521);
            height: vw(1440, 293);
          }
        }

        &:nth-child(3) {
          @include maxMobile {
            display: none;
          }

          @include minTablet {
            top: vw(1440, 442);
            left: vw(1440, -62);
            width: vw(1440, 568);
            height: vw(1440, 345);
          }
        }

        &:nth-child(4) {
          z-index: 2;

          @include maxMobile {
            display: none;
          }

          @include minTablet {
            top: vw(1440, 640);
            left: vw(1440, -223);
            width: vw(1440, 255);
            height: vw(1440, 255);
          }
        }
      }
    }
  }
}
