.s-404 {
  min-height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--color-black);
  background: var(--color-soapstone);

  .title {
    @include title;

    display: block;
    color: var(--color-red);
    margin-bottom: vw(375, 15);

    @include minTablet {
      margin-bottom: vw(1440, 10);
    }
  }

  .heading {
    font-family: var(--font-f-eiko);
    font-style: normal;
    font-weight: 400;
    font-size: vw(375, 90);
    line-height: vw(375, 90);
    letter-spacing: vw(375, -3.6);

    @include minTablet {
      font-size: vw(1440, 244);
      line-height: vw(1440, 244);
      letter-spacing: vw(1440, -9.76);
    }
  }

  .text {
    @include p1;

    width: vw(375, 284);
    margin: vw(375, 10) auto vw(375, 50);
    color: var(--color-black-09);

    @include minTablet {
      width: vw(1440, 284);
      margin: 0 auto vw(1440, 50);
    }
  }

  .button {
    &:first-child {
      margin-right: vw(375, 40);

      @include minTablet {
        margin-right: vw(1440, 50);
      }
    }
  }
}
