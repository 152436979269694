.s-shows-featured {
  @include maxMobile {
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
  }

  @include minTablet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .h2 {
    @include maxMobile {
      @include h3;

      text-align: center;
      margin: vw(375, 100) auto 0;
    }

    @include minTablet {
      display: none;
    }
  }

  .section {
    @include maxMobile {
      padding-bottom: vw(375, 100);
    }
  }

  .info {
    @include minTablet {
      left: vw(1440, 36);
      bottom: vw(1440, 36);
    }
  }

  .background {
    @include minTablet {
      justify-content: center;
    }
  }

  .container {
    @include minTablet {
      position: absolute;
      inset: 0;
      overflow: hidden;
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0) 100%
      );
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 10%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .wrapper {
    @include minTablet {
      height: 100%;
      align-items: center;
      display: flex;
      // justify-content: flex-end;
      padding-left: vw(1440, 150);
      will-change: transform;
      visibility: hidden;
    }
  }

  .show {
    @include minTablet {
      height: vw(1440, 200);
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding-right: vw(1440, 150);
    }
  }

  .label-exclusive {
    @include minTablet {
      position: absolute;
    }
  }
}
