@font-face {
  font-family: 'Founders Grotesk';
  src: url('./assets/fonts/FoundersGrotesk-Light.woff2') format('woff2'),
      url('./assets/fonts/FoundersGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('./assets/fonts/FoundersGrotesk-Regular.woff2') format('woff2'),
      url('./assets/fonts/FoundersGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Eiko';
  src: url('./assets/fonts/PPEiko-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/PPEiko-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Eiko';
  src: url('./assets/fonts/PPEiko-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/PPEiko-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Eiko';
  src: url('./assets/fonts/PPEiko-Regular.woff2') format('woff2'),
      url('./assets/fonts/PPEiko-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PP Eiko';
  src: url('./assets/fonts/PPEiko-Medium.woff2') format('woff2'),
      url('./assets/fonts/PPEiko-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

