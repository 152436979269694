.a-shows-filter {
  .overlay {
    @include maxMobile {
      position: absolute;
      inset: 0;
      visibility: hidden;
      background: var(--color-black-08);
      z-index: 2;
    }

    @include minTablet {
      display: none;
    }
  }

  .filter {
    @include title;

    position: fixed;
    width: vw(375, 98);
    height: vw(375, 46);
    right: vw(375, 20);
    bottom: vw(375, 36);
    border-radius: vw(375, 46);
    transition: border 250ms linear;
    border: 1px solid var(--color-soapstone-02);
    color: var(--color-soapstone);
    visibility: hidden;
    z-index: 3;

    @include minTablet {
      width: vw(1440, 98);
      height: vw(1440, 46);
      right: vw(1440, 36);
      bottom: vw(1440, 36);
      cursor: pointer;
    }

    @include maxMobile {
      background: var(--color-dark-purple);
    }

    @include hover {
      &:hover {
        border-color: rgba(236, 229, 219, 1);
      }
    }
  }

  .cross {
    position: absolute;
    top: vw(375, 20);
    right: vw(375, 20);
    width: vw(375, 16);
    height: vw(375, 16);

    @include minTablet {
      top: vw(1440, 20);
      right: vw(1440, 20);
      width: vw(1440, 9);
      height: vw(1440, 9);
    }
  }

  .menu {
    position: fixed;
    right: vw(375, 20);
    bottom: vw(375, 20);
    padding: vw(375, 25);
    border-radius: vw(375, 8);
    color: var(--color-black);
    background: var(--color-soapstone);
    visibility: hidden;
    z-index: 4;

    @include minTablet {
      right: vw(1440, 36);
      bottom: vw(1440, 36);
      width: vw(1440, 180);
      padding: vw(1440, 25);
      border-radius: vw(1440, 8);
      cursor: pointer;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .category {
    &.is-active {
      pointer-events: none;

      @include minTablet {
        &::after {
          transform: scaleX(1);
        }
      }
    }

    @include maxMobile {
      @include h5;
    }

    @include minTablet {
      cursor: pointer;

      @include h6;
      @include underline;

      display: inline-flex;
    }
  }
}
