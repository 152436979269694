.a-background {
  position: fixed;
  min-height: 100vh;
  inset: 0;
  background: radial-gradient(50% 155% at 70% 55%, rgb(11, 7, 48) 0%, rgb(5, 2, 29) 100%);
  visibility: hidden;

  @include maxMobile {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
