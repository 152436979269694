.a-video-player {
  position: fixed;
  inset: 0;
  z-index: 80;
  visibility: hidden;
  background: var(--color-black);

  @include minTablet {
    cursor: pointer;
  }

  &.is-idle {
    .timeline,
    .controls {
      opacity: 0;
      pointer-events: none;
    }
  }

  .is-device & {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-rich-black);
  }

  .close {
    position: fixed;
    top: vw(375, 20);
    right: vw(375, 20);
    width: vw(375, 60);
    height: vw(375, 60);
    color: var(--color-soapstone);
    z-index: 2;

    @include minTablet {
      display: none;
    }
  }

  .controls,
  .timeline {
    @include minTablet {
      transition: opacity 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
    }

    .is-device & {
      display: none;
    }
  }

  .video {
    position: absolute;

    @include maxMobile {
      height: auto;
    }

    @include minTablet {
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .pause,
  .mute {
    @include p1;

    position: absolute;
    bottom: vw(375, 65);
    color: var(--color-soapstone);
    z-index: 1;

    @include minTablet {
      bottom: vw(1440, 92);
    }
  }

  .pause {
    left: vw(375, 36);

    @include minTablet {
      left: vw(1440, 188);
    }
  }

  .mute {
    right: vw(375, 36);

    @include minTablet {
      right: vw(1440, 188);
    }
  }

  .timeline {
    position: absolute;
    display: flex;
    left: vw(375, 100);
    bottom: vw(375, 70);
    width: vw(375, 222);
    height: vw(375, 5);
    background: rgba(255, 255, 255, 0.2);
    z-index: 2;

    @include minTablet {
      left: 50%;
      bottom: vw(1440, 100);
      width: vw(1440, 910);
      height: vw(1440, 2);
      transform: translateX(-50%);
    }
  }

  .progress,
  .range {
    position: absolute;
    top: 50%;
    left: vw(375, 0);
    width: vw(375, 222);
    height: vw(375, 5);
    border: 0;
    outline: 0;
    appearance: none;
    transform: translateY(-50%);

    @include minTablet {
      left: vw(1440, 0);
      width: vw(1440, 910);
      height: vw(1440, 2);
    }
  }

  .progress {
    pointer-events: none;
    background: none;
  }

  .progress::-webkit-progress-bar {
    background: none;
  }

  .progress::-webkit-progress-value {
    background: var(--color-white);
  }

  .progress::-moz-progress-bar {
    background: none;
  }

  .range {
    z-index: 1;
    background: transparent;
  }

  .range::-webkit-slider-thumb {
    width: vw(1440, 22);
    height: vw(1440, 22);
    background: transparent;
    -webkit-appearance: none;
  }

  .range:focus {
    outline: none;
  }

  .range::-moz-range-track {
    opacity: 0;
  }

  .range::-ms-track {
    width: 100%;
    color: transparent;
    background: transparent;
    border-color: transparent;
  }

  .range::-moz-range-thumb {
    width: 1vw;
    height: 1vw;
    border: none;
    background: transparent;
  }

  .range::-ms-thumb {
    width: 1vw;
    height: 1vw;
    background: transparent;
  }
}
