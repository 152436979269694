.s-text {
  &[data-dark] {
    background: var(--color-soapstone);

    .title {
      color: var(--color-red);
    }

    .heading {
      color: var(--color-rich-black);
    }

    .text {
      p {
        color: var(--color-black-09);
      }
    }
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }

    > * {
      visibility: hidden;
      will-change: transform;
    }
  }

  .title {
    @include title;

    color: var(--color-primary);

    @include minTablet {
      grid-column: 2/7;
      margin-bottom: vw(1440, 20);
    }
  }

  .heading {
    color: var(--color-soapstone);

    @include maxMobile {
      @include h5;

      margin: vw(375, 20) 0 vw(375, 15);
    }

    @include minTablet {
      @include h3;

      grid-column: 2/7;
    }

    &:first-child {
      @include maxMobile {
        margin-top: 0;
      }
    }
  }

  .text {
    @include minTablet {
      grid-column: 8/12;
    }

    p {
      @include p1;

      margin-bottom: vw(375, 20);
      color: var(--color-soapstone-09);

      @include minTablet {
        margin-bottom: vw(1440, 20);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .button {
    margin-top: vw(375, 40);

    @include minTablet {
      margin-top: vw(1440, 40);
    }
  }
}
