.a-notification {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  opacity: 0;

  .modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: var(--color-soapstone);
    background: radial-gradient(50% 155% at 65% 55%, #0b0730 0%, #05021d 100%);

    @include maxMobile {
      left: vw(375, 20);
      right: vw(375, 20);
      bottom: vw(375, 20);
      padding: vw(375, 30);
      border-radius: vw(375, 3);
    }

    @include minTablet {
      right: vw(1440, 33);
      bottom: vw(1440, 33);
      width: vw(1440, 430);
      padding: vw(1440, 50);
      border-radius: vw(1440, 3);
    }
  }

  .title {
    @include title;

    color: var(--color-violet);
  }

  .text {
    @include p1;

    margin: vw(375, 20) 0 vw(375, 30);

    @include minTablet {
      margin: vw(1440, 20) 0 vw(1440, 30);
    }
  }

  .buttons {
    display: flex;
  }

  .accept,
  .decline {
    @include b1;

    color: var(--color-soapstone);

    @include minTablet {
      @include underline;
    }
  }

  .accept {
    margin-right: vw(375, 20);

    @include minTablet {
      margin-right: vw(1440, 20);
    }
  }
}
