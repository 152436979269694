.social-share {
  display: flex;

  li {
    margin: 0 vw(375, 8) 0 0;

    @include minTablet {
      margin: 0 vw(1440, 8) 0 0;
    }
  }

  a, button {
    width: vw(375, 60);
    height: vw(375, 60);
    color: var(--color-black);
    display: block;

    @include minTablet {
      width: vw(1440, 60);
      height: vw(1440, 60);
    }
  }
}
