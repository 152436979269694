.s-thank-you {
  min-height: 100vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  color: var(--color-rich-black);
  background: var(--color-soapstone);

  .container {
    @include container;
  }

  .heading {
    @include h1;

    span {
      font-style: italic;
      color: var(--color-red);
    }
  }

  .text {
    @include h6;

    margin: vw(375, 25) auto vw(375, 40);

    @include minTablet {
      width: vw(1440, 410);
      margin: vw(1440, 25) auto vw(1440, 40);
    }
  }
}
