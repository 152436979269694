.a-music-player {
  @include maxMobile {
    display: none;
  }

  .icon {
    position: absolute;
    z-index: 45;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    @include minTablet {
      right: vw(1440, 33);
      bottom: vw(1440, 33);
      width: vw(1440, 60);
      height: vw(1440, 60);
      border-radius: vw(1440, 60);
    }
  }

  .equalizer {
    @include minTablet {
      width: vw(1440, 20);
      height: vw(1440, 11);
      overflow: hidden;
    }

    svg {
      overflow: visible;

      @include minTablet {
        width: vw(1440, 20);
        height: vw(1440, 11);
      }

      path {
        transform-origin: center;
      }
    }
  }

  .background {
    @include minTablet {
      position: absolute;
      right: vw(1440, 33);
      bottom: vw(1440, 33);
      width: vw(1440, 60);
      height: vw(1440, 60);
      border-radius: vw(1440, 60);
      background: var(--color-violet);
      mix-blend-mode: color-dodge;
      z-index: 44;
      opacity: 0;
    }
  }
}
