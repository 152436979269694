.a-shows-nav {
  position: fixed;
  left: 50%;
  display: block;
  transform: translateX(-50%);
  color: var(--color-soapstone);
  border: 1px solid var(--color-soapstone-02);
  transition: background 500ms linear, border 250ms linear;
  user-select: none;
  z-index: 1;

  @include maxMobile {
    width: vw(375, 199);
    height: vw(375, 46);
    bottom: vw(375, 36);
    border-radius: vw(375, 46);
    background: var(--color-dark-purple);
  }

  @include minTablet {
    bottom: vw(1440, 36);
    width: vw(1440, 199);
    height: vw(1440, 46);
    border-radius: vw(1440, 46);
    background: var(--color-secondary);
    will-change: transform;
    cursor: pointer;
  }

  @include hover {
    &:hover {
      border-color: rgba(236, 229, 219, 1);
    }
  }

  .active {
    @include title;

    position: absolute;
    top: vw(375, 4);
    left: vw(375, 4);
    width: vw(375, 113);
    height: vw(375, 36);
    border-radius: vw(375, 46);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-rich-black);
    background: var(--color-soapstone);
    pointer-events: none;
    z-index: 1;

    @include minTablet {
      top: vw(1440, 4);
      left: vw(1440, 4);
      width: vw(1440, 113);
      height: vw(1440, 36);
      border-radius: vw(1440, 46);
    }
  }

  .list {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 vw(375, 4);
    justify-content: space-between;

    @include minTablet {
      margin: 0 vw(1440, 4);
    }
  }

  .item {
    @include title;

    padding: 0 vw(375, 22);
    line-height: vw(375, 46);

    @include minTablet {
      padding: 0 vw(1440, 22);
      line-height: vw(1440, 46);
    }
  }
}
