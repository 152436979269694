.s-disciplines {
  background: var(--color-soapstone);

  @include p-top-bottom;

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .card {
    position: relative;
    visibility: hidden;
    will-change: transform;

    @include maxMobile {
      padding: vw(375, 30) 0;
      border-bottom: 1px solid var(--color-black-02);
    }

    @include minTablet {
      width: vw(1440, 343);
      height: vw(1440, 480);
      border-radius: vw(1440, 3);
      overflow: hidden;
    }

    &:nth-child(3n + 1) {
      @include minTablet {
        grid-column: 1/5;
      }
    }

    &:nth-child(3n + 2) {
      @include minTablet {
        grid-column: 5/9;
      }
    }

    &:nth-child(3n + 3) {
      @include minTablet {
        grid-column: 9/13;
      }
    }

    &:nth-child(2) {
      @include minTablet {
        margin-top: vw(1440, 60);
      }
    }

    &:nth-child(3) {
      @include minTablet {
        margin-top: vw(1440, 120);
      }
    }

    &:nth-child(4) {
      @include minTablet {
        margin-top: vw(1440, -84);
      }
    }

    &:nth-child(5) {
      @include minTablet {
        margin-top: vw(1440, -24);
      }
    }

    &:nth-child(6) {
      @include minTablet {
        margin-top: vw(1440, 36);
      }
    }
  }

  .heading {
    @include h4;

    z-index: 2;

    @include maxMobile {
      position: relative;
      margin: vw(375, 20) 0;
    }

    @include minTablet {
      position: absolute;
      left: vw(1440, 40);
      bottom: vw(1440, 40);
      color: var(--color-soapstone);
    }

    &::after {
      content: ".";
      color: var(--color-red);
      font-feature-settings: "ss05" on;
    }
  }

  .arrow {
    position: absolute;
    top: vw(375, 10);
    right: vw(375, 20);
    width: vw(375, 12);
    height: vw(375, 8);

    @include minTablet {
      display: none;
    }

    path {
      fill: var(--color-red);
    }
  }

  .text {
    @include p1;

    z-index: 2;

    @include minTablet {
      position: absolute;
      right: vw(1440, 40);
      bottom: vw(1440, 40);
      left: vw(1440, 40);
      color: var(--color-soapstone);
      padding-top: vw(1440, 10);
      visibility: hidden;
    }
  }

  .overlay {
    @include maxMobile {
      display: none;
    }
    
    @include minTablet {
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #070323 100%);
      visibility: hidden;
      z-index: 1;
    }
  }

  .image {
    @include maxMobile {
      position: relative;
      width: vw(375, 335);
      height: vw(375, 386);
      border-radius: vw(375, 3);
      overflow: hidden;
    }

    @include minTablet {
      position: absolute;
      inset: 0;
    }

    &::before {
      @include minTablet {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(to bottom, rgba(7, 3, 35, 0) 80%, rgba(7, 3, 35, 0.6) 100%);
        z-index: 1;
      }
    }
  }

  .img {
    position: absolute;
    inset: 0;
  }
}
