.header-text {
  text-align: center;
  color: var(--color-black);
  background: var(--color-soapstone);
  padding: vw(375, 125) 0 vw(375, 60);

  @include minTablet {
    padding: vw(1440, 200) 0 vw(1440, 100);
  }

  .container {
    @include container;
  }

  .heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .line {
    visibility: hidden;
    will-change: transform;

    &:nth-child(1) {
      @include title;

      margin-bottom: vw(375, 15);
      color: var(--color-red);

      @include minTablet {
        margin-bottom: vw(1440, 15);
      }
    }

    &:nth-child(2) {
      @include h1;

      &::after {
        content: ".";
        color: var(--color-red);
        font-feature-settings: "ss05" on;
      }
    }
  }

  .text {
    position: relative;
    visibility: hidden;
    z-index: 1;

    @include maxMobile {
      @include h6;

      margin: vw(375, 10) auto 0;
    }

    @include minTablet {
      @include h5;

      width: vw(1440, 532);
      margin: vw(1440, 45) auto 0;
    }

    div {
      will-change: transform;
    }
  }

  .graphic {
    position: absolute;
    width: vw(375, 213);
    height: vw(375, 141);

    path {
      fill: var(--color-white);
      transform-origin: center;
      transform-box: fill-box;
      visibility: hidden;
    }

    @include minTablet {
      width: vw(1440, 264);
      height: vw(1440, 174);
    }
  }

  .waves {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      top: 0;
      left: 0;
      width: vw(1440, 1302);
      height: vw(1440, 130);
    }

    path {
      fill: #fefefd;
    }
  }
}
