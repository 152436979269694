.s-sound-production {
  color: var(--color-soapstone);
  background: radial-gradient(50% 155% at 65.49% 50%, #0b0730 0%, #05021d 100%);

  @include p-top-bottom;

  .heading-section {
    margin-bottom: vw(375, 25);

    @include minTablet {
      margin-bottom: vw(1440, 55);
    }
  }

  .intro {
    @include h6;

    position: relative;
    text-align: center;
    margin: 0 vw(375, 20) vw(375, 60);

    @include minTablet {
      width: vw(1440, 532);
      margin: 0 auto vw(1440, 75);
    }
  }

  .container {
    @include container;

    position: relative;
    padding: vw(375, 60) 0;
    border-top: 1px solid var(--color-soapstone-02);
    border-bottom: 1px solid var(--color-soapstone-02);

    @include minTablet {
      @include grid;

      padding: vw(1440, 75) 0;
    }
  }

  .heading {
    @include h2;

    @include minTablet {
      margin-bottom: vw(1440, 10);
    }
  }

  .text {
    @include p1;

    color: var(--color-soapstone-09);
  }

  .player {
    @include minTablet {
      grid-column: 3/8;
    }
  }

  .controls {
    display: flex;
    margin: vw(375, 30) 0;

    @include minTablet {
      margin: vw(1440, 20) 0 0;
    }

    > * {
      width: vw(375, 60);
      height: vw(375, 60);

      @include minTablet {
        width: vw(1440, 60);
        height: vw(1440, 60);
      }
    }
  }

  .play {
    margin: 0 vw(375, 8);

    @include minTablet {
      margin: 0 vw(1440, 8);
    }

    path {
      &:last-child {
        opacity: 0;
      }
    }

    &.is-playing {
      path {
        &:first-child {
          opacity: 0;
        }

        &:last-child {
          opacity: 1;
        }
      }
    }
  }

  .show {
    @include minTablet {
      grid-column: 8/12;
    }
  }

  .title {
    @include title;

    color: var(--color-violet);
    margin-bottom: vw(375, 10);

    @include minTablet {
      margin-bottom: vw(1440, 10);
    }
  }

  .link {
    @include p1;

    display: inline-block;
    margin-top: vw(375, 20);
    text-decoration: underline;
    color: var(--color-soapstone-09);

    @include minTablet {
      margin-top: vw(1440, 20);
    }
  }

  .background {
    position: absolute;
    inset: 0;
  }

  button {
    color: var(--color-soapstone);
  }
}
