.a-nav {
  position: fixed;
  top: vw(375, 20);
  right: vw(375, 20);
  z-index: 25;

  @include minTablet {
    top: vw(1440, 36);
    right: vw(1440, 36);
  }

  .burger {
    position: absolute;
    top: 0;
    right: 0;
    width: vw(375, 60);
    height: vw(375, 60);
    color: var(--color-rich-black);

    @include minTablet {
      width: vw(1440, 60);
      height: vw(1440, 60);
      visibility: hidden;
    }

    .is-light & {
      color: var(--color-soapstone);

      @include maxMobile {
        color: var(--color-black);
      }
    }

    circle {
      transition: opacity 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */

      @include maxMobile {
        opacity: 1;
        stroke-opacity: 0.2;
        fill: var(--color-soapstone);
      }
    }

    @include hover {
      &:hover {
        circle {
          opacity: 1;
        }
      }
    }
  }

  .buttons {
    display: flex;
    visibility: hidden;

    @include maxMobile {
      display: none;
    }
  }

  li {
    position: relative;
    margin: 0 vw(1440, 9);
    color: var(--color-rich-black);

    @include minTablet {
      @include underline;
    }

    .is-light & {
      color: var(--color-soapstone);
    }

    &:first-child {
      a {
        &::after {
          background: var(--color-white);
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-active {
      pointer-events: none;

      a {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  a {
    @include nav;

    color: var(--color-rich-black);
    transition: color 150ms cubic-bezier(0.25, 0.25, 0.75, 0.75); /* linear */
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      position: absolute;
      top: vw(1440, 30);
      width: vw(1440, 4);
      height: vw(1440, 4);
      display: block;
      opacity: 0;
      border-radius: 100%;
      pointer-events: none;
      background: var(--color-red);
      transform: translateY(vw(1440, 20));
      transition: all 250ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
      content: "";
    }

    .is-light & {
      color: var(--color-soapstone);
    }
  }
}
