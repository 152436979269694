.s-history {
  overflow: hidden;
  user-select: none;
  color: var(--color-soapstone);

  @include p-top-bottom;

  @include maxMobile {
    overflow: hidden;
  }

  @include minTablet {
    cursor: grab;
  }

  .container {
    display: inline-flex;
  }

  .block {
    width: vw(375, 276);
    margin-right: vw(375, 20);

    @include minTablet {
      width: vw(1440, 627);
      margin-right: vw(1440, 36);
    }

    &:first-child {
      margin-left: vw(375, 20);

      @include minTablet {
        margin-left: vw(1440, 170);
      }
    }

    &:last-child {
      margin-right: vw(375, 20);

      @include minTablet {
        margin-right: vw(1440, 170);
      }
    }
  }

  .image {
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 60%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 60%, rgba(0, 0, 0, 0) 100%);
  }

  .img {
    -webkit-mask-image: linear-gradient(135deg, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(135deg, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 0) 100%);
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 0 0 0 vw(375, 20);
    margin-top: vw(375, -25);

    @include minTablet {
      padding: 0 vw(1440, 95);
      margin-top: vw(1440, -130);
    }
  }

  .year {
    height: vw(375, 70);
    display: block;
    font-family: var(--font-f-eiko);
    font-size: vw(375, 90);
    font-weight: 400;
    line-height: vw(375, 70);
    letter-spacing: vw(375, -3.6);

    @include minTablet {
      height: vw(1440, 120);
      font-size: vw(1440, 144);
      line-height: vw(1440, 120);
      letter-spacing: vw(1440, -5.76);
    }
  }

  .title {
    @include title;

    color: var(--color-violet);
    margin: vw(375, 25) 0;

    @include minTablet {
      margin: vw(1440, 25) 0;
    }
  }

  .text {
    @include p1;

    color: var(--color-soapstone-09);

    a {
      text-decoration: underline;
    }
  }
}
