.s-numbers {
  color: var(--color-black);
  background: var(--color-soapstone);

  @include p-top;
  
  .container {
    @include container;
    @include p-bottom;

    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;

    @include maxMobile {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 1px;
      background: var(--color-black-02);
      content: "";
    }
  }

  .element {
    width: vw(375, 100);
    height: vw(375, 100);
    margin: auto;

    @include minTablet {
      width: vw(1440, 248);
      height: vw(1440, 248);
    }
  }

  .number {
    display: flex;
    flex-direction: column;

    @include maxMobile {
      margin: auto;
    }

    &:nth-child(1) {
      @include maxMobile {
        flex-basis: 100%;
        margin-bottom: vw(375, 25);
      }

      path {
        fill: var(--color-white);
      }
    }

    &:nth-child(2) {
      @include minTablet {
        margin: 0 vw(1440, 105);
      }
      path {
        fill: var(--color-violet);
      }
    }

    &:nth-child(3) {
      path {
        fill: var(--color-red);
      }
    }
  }

  .digit {
    font-family: var(--font-f-eiko);
    font-size: vw(375, 64);
    font-weight: 400;
    line-height: vw(375, 80);
    margin-top: vw(375, -40);

    @include minTablet {
      font-size: vw(1440, 144);
      line-height: vw(1440, 150);
      letter-spacing: vw(1440, -5.76);
      margin-top: vw(1440, -70);
    }
  }

  .title {
    @include title;

    color: var(--color-red);
  }
}
