.header-home {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  color: var(--color-soapstone);

  @include maxMobile {
    height: calc(var(--vh, 1vh) * 100);
  }

  @include minTablet {
    cursor: pointer;
  }

  svg {
    overflow: visible;
  }

  .content {
    @include container;

    position: relative;

    @include maxMobile {
      width: 100%;
    }
  }

  .heading {
    width: vw(375, 290);
    height: vw(375, 222);

    @include minTablet {
      width: vw(1440, 470);
      height: vw(1440, 320);
    }

    g {
      visibility: hidden;
      will-change: transform;
    }
  }

  .title {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .text {
    @include h6;

    width: vw(375, 276);
    margin: 0 0 vw(375, 30);
    visibility: hidden;
    will-change: transform;

    @include minTablet {
      width: vw(1440, 205);
      text-indent: vw(1440, 50);
      margin: vw(1440, 34) 0 0 vw(1440, 138);
    }
  }

  .button {
    @include maxMobile {
      visibility: hidden;
      will-change: transform;
    }

    @include minTablet {
      display: none;
    }
  }

  .mask {
    position: absolute;
    inset: 0;
    mask-image: url("./assets/img/hero-mask.png");
    -webkit-mask-image: url("./assets/img/hero-mask.png");
    mask-position: bottom;
    -webkit-mask-position: bottom;
    mask-size: cover;
    -webkit-mask-size: cover;
  }

  .background {
    position: absolute;
    inset: 0;
  }

  .video {
    position: absolute;
    inset: 0;
    visibility: hidden;
    -webkit-mask-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.2) 100%);
  }

  .image {
    display: none;
  }

  .img {
    position: absolute;
    inset: 0;
  }

  .waves {
    position: absolute;
    left: 0;
    z-index: 1;
    mix-blend-mode: color-dodge;

    @include maxMobile {
      display: none;
    }

    @include minTablet {
      bottom: vw(1440, -180);
      width: 100%;
      height: vw(1440, 314);
    }
  }
}
