.header-show {
  color: var(--color-soapstone);

  @include maxMobile {
    display: flex;
    flex-direction: column;
    padding-top: vw(375, 125);
  }

  @include minTablet {
    height: 100vh;
  }

  &[data-cursor-label] {
    cursor: pointer;

    .media {
      &::before {
        content: "Play";

        @include title;
      }
    }
  }

  .container {
    @include container;

    position: relative;

    @include minTablet {
      @include grid;

      height: 100%;
    }
  }

  .head {
    @include minTablet {
      grid-column: 1/9;
      margin: auto 0 vw(1440, 70);
    }
  }

  .assignment {
    @include minTablet {
      grid-column: 9/13;
      margin: auto 0 vw(1440, 75);
    }
  }

  .head > *,
  .assignment > * {
    visibility: hidden;
    will-change: transform;
  }

  .exclusive {
    @include title;

    display: inline-flex;
    padding: vw(375, 10);
    margin-right: vw(375, 18);
    border: 1px solid rgba(236, 229, 219, 0.2);
    border-radius: 4px;

    @include minTablet {
      padding: vw(1440, 10);
      margin-right: vw(1440, 18);
    }
  }

  .category {
    @include title;

    color: var(--color-primary);
    display: inline-flex;
  }

  .heading {
    @include h1;

    margin: vw(375, 11) 0 vw(375, 20);

    @include minTablet {
      margin: vw(1440, 11) 0 0;
    }
  }

  .title {
    @include title;

    color: var(--color-primary);

    @include maxMobile {
      display: none;
    }
  }

  .text {
    @include p1;

    color: var(--color-soapstone-09);

    @include minTablet {
      margin-top: vw(1440, 15);
    }
  }

  .media {
    @include maxMobile {
      order: 2;
      height: vw(375, 210);
      margin-top: vw(375, 55);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include minTablet {
      position: absolute;
      inset: 0;
      height: 100%;
      -webkit-mask-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
      mask-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%);
    }

    &::before {
      @include maxMobile {
        position: absolute;
        width: vw(375, 90);
        height: vw(375, 90);
        border-radius: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-soapstone);
        background: var(--color-primary);
      }
    }
  }

  .buttons {
    margin-top: vw(375, 40);

    @include minTablet {
      margin-top: vw(1440, 40);
    }
  }

  .button {
    &:first-child {
      margin-right: vw(375, 36);

      @include minTablet {
        margin-right: vw(1440, 36);
      }
    }
  }

  .sphere {
    @include maxMobile {
      mix-blend-mode: normal;
      color: var(--color-primary);
    }
  }
}
