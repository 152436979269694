.s-show-details {
  @include p-bottom;

  @include maxMobile {
    padding-top: vw(375, 55);
  }

  @include minTablet {
    padding-top: vw(1440, 220);

    &::before {
      position: absolute;
      top: vw(1440, 70);
      left: vw(1440, 170);
      width: vw(1440, 250);
      border-bottom: 1px solid var(--color-soapstone-02);
      content: "";
    }
  }

  .decor {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      right: vw(1440, -120);
      width: vw(1440, 450);
      height: vw(1440, 450);
      mix-blend-mode: overlay;
    }
  }

  .container {
    @include container;

    @include maxMobile {
      display: flex;
      flex-direction: column;
    }

    @include minTablet {
      @include grid;
    }
  }

  .column {
    &:nth-child(1) {
      @include maxMobile {
        order: 2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include minTablet {
        grid-column: 1/4;
      }
    }

    &:nth-child(2) {
      @include maxMobile {
        order: 1;
        margin-bottom: vw(375, 35);
      }

      @include minTablet {
        grid-column: 4/11;
      }

      p {
        margin-bottom: vw(375, 23);
        color: var(--color-soapstone);

        @include minTablet {
          margin-bottom: vw(1440, 23);
        }

        &:first-child {
          @include maxMobile {
            @include h5;

            text-indent: vw(375, 60);
          }

          @include minTablet {
            @include h3;

            text-indent: vw(1440, 60);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:not(:first-child) {
          @include p1;

          color: var(--color-soapstone-09);

          @include minTablet {
            width: vw(1440, 497);
            padding-left: vw(1440, 60);
          }
        }
      }
    }
  }

  .wrap {
    @include maxMobile {
      display: flex;
      flex-direction: column;
    }

    &:nth-child(2) {
      @include maxMobile {
        padding-left: vw(375, 10);
      }
    }
    
    .block {
      @include maxMobile {
        margin-bottom: vw(375, 30);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .block {
    @include minTablet {
      margin-bottom: vw(1440, 27);
    }
  }

  .title {
    @include title;

    margin-bottom: vw(375, 8);
    color: var(--color-primary);

    @include minTablet {
      margin-bottom: vw(1440, 8);
    }
  }

  a,
  li {
    @include p1;

    color: var(--color-soapstone-09);
  }

  a {
    text-decoration: underline;
  }
}
