.s-friends-partners {
  color: var(--color-black);
  background: var(--color-soapstone);

  @include p-top-bottom;

  .heading-section {
    @include minTablet {
      margin-bottom: vw(1440, 150);
    }
  }

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .content {
    @include minTablet {
      grid-column: 1/5;
    }
  }

  .title {
    @include title;

    color: var(--color-red);
    margin-bottom: vw(375, 20);

    @include minTablet {
      margin-bottom: vw(1440, 10);
    }
  }

  .text {
    @include p1;

    color: var(--color-black-09);
  }

  .clients {
    @include maxMobile {
      text-align: center;
      margin: vw(375, 50) 0;
    }

    @include minTablet {
      grid-column: 6/13;
      margin-bottom: vw(1440, 80);
    }

    p {
      @include h3;
    }
  }

  .primary {
    p {
      @include maxMobile {
        &::after {
          content: " And many more...";
        }
      }
    }
  }

  .secondary {
    @include maxMobile {
      display: none;
    }

    p {
      @include minTablet {
        &::after {
          content: " And many more...";
        }
      }
    }
  }

  .family {
    @include minTablet {
      grid-column: 6/13;
    }
  }

  .logos {
    display: flex;

    @include minTablet {
      margin-top: vw(1440, 40);
    }
  }

  .logo {
    width: vw(375, 155);
    height: vw(375, 155);

    @include minTablet {
      width: vw(1440, 184);
      height: vw(1440, 184);
    }

    &:nth-child(2) {
      margin: 0 vw(375, 20);

      @include minTablet {
        margin: 0 vw(1440, 40);
      }
    }
  }
}
