.s-sign-up {
  .column {
    &:nth-child(1) {
      @include minTablet {
        align-self: center;
      }
    }

    &:nth-child(2) {
      @include maxMobile {
        padding-top: vw(375, 55);
      }
    }
  }

  .checkboxes {
    @include minTablet {
      display: flex;
    }
  }

  .checkbox {
    &:nth-child(1) {
      @include maxMobile {
        margin-bottom: vw(375, 20);
      }
      @include minTablet {
        margin-right: vw(1440, 30);
      }
    }
  }
}
