.a-menu {
  position: fixed;
  inset: 0;
  z-index: 50;
  color: var(--color-soapstone);
  background: var(--color-black);
  visibility: hidden;

  @include maxMobile {
    overflow: scroll;
    background: radial-gradient(50% 155% at 65% 50%, #0b0730 0%, #05021d 100%);
  }

  .close {
    position: fixed;
    top: vw(375, 20);
    right: vw(375, 20);
    width: vw(375, 60);
    height: vw(375, 60);
    color: var(--color-soapstone);
    z-index: 2;

    @include minTablet {
      top: vw(1440, 36);
      right: vw(1440, 36);
      width: vw(1440, 60);
      height: vw(1440, 60);
    }

    circle {
      transition: opacity 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
    }

    @include hover {
      &:hover {
        circle {
          opacity: 1;
        }
      }
    }
  }

  .gradient {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      left: 50%;
      width: 100vh;
      height: 100vh;
      display: block;
      transform: translateX(-50%);
      background: radial-gradient(50% 50% at 50% 50%, #080429 0%, #05021d 100%);
    }
  }

  .container {
    @include container;

    position: relative;
    z-index: 1;

    @include maxMobile {
      padding-top: vw(375, 125);
    }

    @include minTablet {
      @include grid;

      height: 100%;
      align-items: center;
    }
  }

  .column {
    &:nth-child(1) {
      @include minTablet {
        grid-column: 3/8;
      }
    }

    &:nth-child(2) {
      @include minTablet {
        grid-column: 8/12;
      }
    }
  }

  .block {
    @include maxMobile {
      margin-bottom: vw(375, 40);
    }

    &:first-child {
      @include minTablet {
        margin-bottom: vw(1440, 80);
      }
    }

    &:last-child {
      li {
        &:nth-child(4) {
          margin-top: vw(1440, 25);
        }
      }
    }

    li {
      @include h6;

      @include minTablet {
        will-change: transform;
      }
    }

    a {
      @include minTablet {
        @include underline;
      }
    }
  }

  .main {
    @include maxMobile {
      margin-bottom: vw(375, 50);
    }

    li {
      margin: vw(375, 5) 0;

      @include minTablet {
        margin: vw(1440, 5) 0;
      }
    }

    a {
      @include h1;

      display: block;

      @include minTablet {
        will-change: transform;
      }

      &::after {
        @include minTablet {
          content: ".";
          display: inline-flex;
          color: var(--color-red);
          font-feature-settings: "ss05" on;
          transform: translateX(vw(1440, 25));
          transition: all 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
          opacity: 0;
        }
      }

      @include hover {
        &:hover {
          &::after {
            opacity: 1;
            transform: translateX(vw(1440, 0));
          }
        }
      }
    }
  }

  .title {
    @include title;

    color: var(--color-violet);
    margin-bottom: vw(375, 15);

    @include minTablet {
      margin-bottom: vw(1440, 15);
      will-change: transform;
    }
  }

  .social-links {
    position: relative;
    z-index: 1;

    @include maxMobile {
      margin: 0 vw(375, 20);
      padding-bottom: vw(375, 60);
    }

    @include minTablet {
      position: absolute;
      right: vw(1440, 36);
      bottom: vw(1440, 36);
    }
  }

  .eyes-of-plugged {
    position: fixed;
    inset: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    svg {
      position: absolute;

      @include maxMobile {
        top: vw(375, 100);
        left: vw(375, 250);
        width: vw(375, 630);
        height: vw(375, 630);
      }

      @include minTablet {
        left: 50%;
        width: vw(1440, 960);
        height: vw(1440, 960);
        transform: translateX(-50%);
      }

      &:nth-child(1) {
        @include maxMobile {
          transform: rotate(-60deg);
          transform-origin: center center;
        }

        @include minTablet {
          top: vw(1440, -840);
        }
      }

      &:nth-child(2) {
        @include maxMobile {
          display: none;
        }

        @include minTablet {
          bottom: vw(1440, -840);
        }
      }

      path {
        fill: #100957;
      }
    }
  }
}
