.s-media {
  &[data-dark] {
    background: var(--color-soapstone);
  }

  &[data-light] {
    color: var(--color-soapstone);
  }

  .container {
    @include container;
    @include grid;

    position: relative;
  }

  .element {
    position: absolute;
    top: vw(375, 118);
    right: vw(375, -60);
    width: vw(375, 118);
    height: vw(375, 118);
    mix-blend-mode: color-dodge;
    z-index: 3;

    @include minTablet {
      top: vw(1440, 80);
      right: vw(1440, -140);
      width: vw(1440, 280);
      height: vw(1440, 280);
    }

    path {
      fill: var(--color-violet);
    }
  }

  .img,
  .video {
    z-index: 2;
  }

  .two {
    .img,
    .video {
      &:nth-child(1) {
        grid-column: 1/6;
        height: vw(375, 155);

        @include minTablet {
          grid-column: 1/12;
          height: vw(1440, 565);
        }
      }
      &:nth-child(2) {
        grid-column: 4/7;
        height: vw(375, 104);
        margin-top: vw(375, -50);

        @include minTablet {
          grid-column: 8/13;
          height: vw(1440, 290);
          margin-top: vw(1440, -150);
        }
      }
    }
  }

  .three {
    .img,
    .video {
      &:nth-child(1) {
        grid-column: 1/6;
        height: vw(375, 183);

        @include minTablet {
          grid-column: 1/9;
          height: vw(1440, 478);
        }
      }
      &:nth-child(2) {
        grid-column: 3/7;
        height: vw(375, 145);
        margin-top: vw(375, 20);

        @include minTablet {
          grid-column: 6/11;
          height: vw(1440, 292);
          margin-top: vw(1440, -150);
        }
      }
      &:nth-child(3) {
        grid-column: 2/5;
        height: vw(375, 105);
        margin-top: vw(375, 20);

        @include minTablet {
          grid-column: 9/13;
          height: vw(1440, 227);
          margin-top: vw(1440, -400);
        }
      }
    }
  }
}
