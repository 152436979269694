.s-quote {
  @include p-bottom;

  .container {
    @include container;

    @include minTablet {
      @include grid;
    }
  }

  .media {
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 60%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 60%, rgba(0, 0, 0, 0) 100%);

    @include maxMobile {
      margin: 0 vw(375, -20);
    }

    @include minTablet {
      grid-column: 1/13;
    }
  }

  .img,
  .video {
    -webkit-mask-image: linear-gradient(135deg, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(135deg, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 0) 100%);
  }

  .content {
    position: relative;
    z-index: 1;

    @include minTablet {
      grid-column: 2/9;
    }
  }

  .blockquote {
    color: var(--color-soapstone);

    @include maxMobile {
      @include h5;

      text-indent: vw(375, 60);
      margin: vw(375, -60) 0 vw(375, 23);
    }

    @include minTablet {
      @include h3;

      text-indent: vw(1440, 60);
      margin: vw(1440, -60) 0 vw(1440, 23);
    }
  }

  .quotee {
    @include title;

    color: var(--color-primary);
  }
}
