.a-footer {
  color: var(--color-soapstone);
  background: radial-gradient(50% 50% at 50% 50%, #080429 0%, #05021d 100%);
  overflow: hidden;

  @include maxMobile {
    padding: vw(375, 70) 0 vw(375, 40);
  }

  @include minTablet {
    height: 100vh;
    min-height: vw(1440, 700);
    max-height: vw(1440, 810);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: vw(1440, 145) 0 vw(1440, 45);
  }

  .waves {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: vw(1440, 256);
      mix-blend-mode: color-dodge;
    }
  }

  .container {
    @include container;

    position: relative;
    z-index: 1;

    @include minTablet {
      @include grid;
    }
  }

  .column {
    @include maxMobile {
      margin: vw(375, 40) 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(1) {
      @include minTablet {
        grid-column: 1/4;
      }
    }

    &:nth-child(2) {
      @include minTablet {
        grid-column: 6/8;
      }
    }

    &:nth-child(3) {
      @include minTablet {
        grid-column: 9/12;
      }
    }
  }

  .contact,
  .events {
    li {
      @include h6;

      @include maxMobile {
        font-size: vw(375, 18);
        line-height: vw(375, 30);
        letter-spacing: vw(375, 0.18);
      }
    }

    a {
      @include minTablet {
        @include underline;
      }
    }
  }

  .contact {
    li {
      &:nth-child(4) {
        margin-top: vw(375, 30);

        @include minTablet {
          margin-top: vw(1440, 30);
        }
      }
    }
  }

  .social-links {
    @include maxMobile {
      margin-bottom: vw(375, 40);
      padding-bottom: vw(375, 40);
      border-bottom: 1px solid var(--color-soapstone-02);
    }

    @include minTablet {
      grid-column: 9/12;
      margin-bottom: vw(1440, 160);
    }
  }

  .logo {
    width: vw(375, 217);
    height: vw(375, 35);

    @include maxMobile {
      margin-bottom: vw(375, 40);
    }

    @include minTablet {
      grid-column: 1/6;
      width: vw(1440, 176);
      height: vw(1440, 29);
    }
  }

  .legal {
    display: flex;
    justify-content: space-between;

    @include maxMobile {
      flex-wrap: wrap;
    }

    @include minTablet {
      grid-column: 6/13;
    }

    li {
      @include maxMobile {
        &:nth-child(3) {
          flex-basis: 65%;
        }
      }

      @include minTablet {
        display: flex;
        align-items: center;
      }
    }

    a {
      color: var(--color-soapstone-08);

      @include maxMobile {
        @include p1;
      }

      @include minTablet {
        @include p2;
        @include underline;
      }
    }
  }

  .title {
    @include title;

    margin-bottom: vw(375, 20);
    color: var(--color-mystic);
    opacity: 0.6;

    @include minTablet {
      margin-bottom: vw(1440, 30);
    }
  }

  .nav {
    a {
      @include h2;

      @include maxMobile {
        font-size: vw(375, 40);
        line-height: vw(375, 54);
        letter-spacing: vw(375, -0.8);
      }

      &::after {
        @include minTablet {
          content: ".";
          display: inline-flex;
          color: var(--color-red);
          font-feature-settings: "ss05" on;
          transform: translateX(vw(1440, 25));
          transition: all 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
          opacity: 0;
        }
      }

      @include hover {
        &:hover {
          &::after {
            opacity: 1;
            transform: translateX(vw(1440, 0));
          }
        }
      }
    }
  }

  .exoape {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      left: vw(1440, -20);
      width: vw(1440, 26);
      height: vw(1440, 25);
      margin-right: vw(1440, 5);
    }
  }
}
