.social-links {
  display: flex;

  li {
    &:nth-child(2) {
      margin: 0 vw(375, 8);

      @include minTablet {
        margin: 0 vw(1440, 8);
      }
    }

    @include hover {
      &:hover {
        circle {
          stroke-opacity: 1;
        }
      }
    }
  }

  a {
    width: vw(375, 60);
    height: vw(375, 60);
    display: block;

    @include minTablet {
      width: vw(1440, 60);
      height: vw(1440, 60);
    }
  }

  circle {
    transition: stroke-opacity 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
  }
}
