.s-promises {
  color: var(--color-black);
  background: var(--color-soapstone);

  @include p-top-bottom;
  
  .container {
    @include container;
  }

  .element {
    position: absolute;
    width: vw(375, 158);
    height: vw(375, 158);
    mix-blend-mode: color-dodge;

    @include minTablet {
      width: vw(1440, 248);
      height: vw(1440, 248);
    }

    path {
      fill: var(--color-violet);
    }
  }

  .image {
    border-radius: vw(375, 3);
    overflow: hidden;

    @include maxMobile {
      margin-bottom: vw(375, 15);
    }

    @include minTablet {
      border-radius: vw(1440, 3);
    }
  }

  .content {
    @include minTablet {
      position: absolute;
      width: vw(1440, 437);
    }
  }

  .digit {
    @include title;

    color: var(--color-red);

    @include minTablet {
      position: absolute;
      top: vw(1440, 7);
      left: vw(1440, -50);
    }
  }

  .heading {
    @include h2;

    @include maxMobile {
      margin: vw(375, 15) 0;
    }
  }

  .text {
    @include p1;

    color: var(--color-black-09);

    @include minTablet {
      width: vw(1440, 342);
      margin-top: vw(1440, 23);
    }
  }

  .block {
    position: relative;
    margin: vw(375, 55) 0;

    @include minTablet {
      display: flex;
      align-items: center;
      margin: vw(1440, 150) 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      .image {
        width: vw(375, 335);
        height: vw(375, 353);

        @include minTablet {
          width: vw(1440, 532);
          height: vw(1440, 559);
        }
      }

      .content {
        @include minTablet {
          left: vw(1440, 663);
        }
      }

      .element {
        top: vw(375, 244);
        left: vw(375, 146);

        @include minTablet {
          top: vw(1440, 358);
          left: vw(1440, 379);
        }
      }
    }

    &:nth-child(2) {
      .image {
        width: vw(375, 276);
        height: vw(375, 353);

        @include minTablet {
          width: vw(1440, 343);
          height: vw(1440, 438);
          margin-left: auto;
        }
      }

      .content {
        @include minTablet {
          left: vw(1440, 284);
        }
      }

      .element {
        top: vw(375, 244);
        left: vw(375, 146);

        @include minTablet {
          top: vw(1440, 241);
          left: vw(1440, 633);
        }
      }
    }

    &:nth-child(3) {
      .image {
        width: vw(375, 276);
        height: vw(375, 255);

        @include minTablet {
          width: vw(1440, 437);
          height: vw(1440, 403);
          margin-left: vw(1440, 95);
        }
      }

      .content {
        @include minTablet {
          left: vw(1440, 663);
        }
      }

      .element {
        top: vw(375, 147);
        left: vw(375, 146);

        @include minTablet {
          top: vw(1440, 206);
          left: vw(1440, 408);
        }
      }
    }
  }
}
