.s-numbers + .s-insights-select {
  .waves {
    display: none;
  }
}

.s-insights-select {
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  background: var(--color-soapstone);

  .page-insight & {
    padding-top: 0;
  }

  @include maxMobile {
    padding: vw(375, 100) 0;
  }

  @include minTablet {
    padding: vw(1440, 190) 0;
  }

  .container {
    @include container;

    z-index: 1;
    position: relative;
    
    @include minTablet {
      @include grid;
    }
  }

  .insights-post {
    &:nth-child(1) {
      grid-column: 2/6;
    }

    &:nth-child(2) {
      grid-column: 7/13;

      @include minTablet {
        margin-bottom: vw(1440, 150);
      }
    }

    &:nth-child(3) {
      grid-column: 1/7;
    }

    &:nth-child(4) {
      grid-column: 8/12;

      @include minTablet {
        margin-top: vw(1440, 130);
      }
    }

    &:nth-child(1),
    &:nth-child(4) {
      .image {
        @include minTablet {
          height: vw(1440, 229);
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      .image {
        @include minTablet {
          height: vw(1440, 357);
        }
      }

      .content {
        @include minTablet {
          width: vw(1440, 437);
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .waves {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: vw(1440, 711);
    }

    path {
      fill: #f0ebec;
    }

    .page-insight & {
      display: none;
    }
  }

  .bulls-eye {
    @include maxMobile {
      display: none;
    }

    @include minTablet {
      position: absolute;
      top: 45%;
      right: vw(1440, -220);
      width: vw(1440, 420);
      height: vw(1440, 419);
    }

    path {
      fill: #f0ebec;
    }
  }

  .button {
    margin: vw(375, 50) auto auto;

    @include minTablet {
      margin: vw(1440, 150) auto auto;
    }
  }
}
