.insights-post {
  visibility: hidden;
  will-change: transform;

  @include maxMobile {
    margin-bottom: vw(375, 50);
  }

  @include hover {
    &:hover {
      .img {
        transform: scale(1.1);
      }
    }
  }

  .image {
    overflow: hidden;
    border-radius: vw(375, 3);
    background: var(--color-black);

    @include maxMobile {
      height: vw(375, 225);
    }

    @include minTablet {
      border-radius: vw(1440, 3);
    }
  }

  .img {
    @include minTablet {
      will-change: transform;
      transition: transform 500ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */
    }
  }

  .title {
    @include h4;

    margin: vw(375, 23) 0 vw(375, 7);

    @include minTablet {
      margin: vw(1440, 23) 0 vw(1440, 18);
    }
  }

  .excerpt {
    @include h6;
  }
}
