.s-call-to-action {
  color: var(--color-soapstone);

  .container,
  .content {
    position: relative;
  }

  .title {
    @include title;
  }

  .heading {
    margin: vw(375, 25) 0 vw(375, 22);

    @include minTablet {
      margin: vw(1440, 25) 0 vw(1440, 22);
    }
  }

  .text {
    @include p1;

    color: var(--color-soapstone-09);

    @include maxMobile {
      margin-bottom: vw(375, 40);
    }
  }

  .button {
    @include minTablet {
      align-self: flex-end;
      justify-self: flex-start;
    }
  }

  .background {
    @include maxMobile {
      position: relative;
      width: vw(375, 335);
      height: vw(375, 189);
      border-radius: vw(375, 3);
      overflow: hidden;
      margin: auto;
    }

    @include minTablet {
      position: absolute;
      inset: 0;
    }
  }

  .img {
    position: absolute;
    inset: 0;
  }

  // contained
  .is-contained {
    @include container;

    padding: vw(375, 70) 0;

    @include minTablet {
      padding: vw(1440, 90) 0;
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--color-soapstone-02);
      content: "";

      @include minTablet {
        left: vw(1440, 95);
        right: vw(1440, 95);
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .content {
      @include minTablet {
        @include grid;
      }
    }

    .title {
      color: var(--color-primary);

      @include minTablet {
        grid-column: 3/8;
      }
    }

    .heading {
      @include h2;

      @include minTablet {
        grid-column: 3/8;
      }
    }

    .text {
      @include minTablet {
        grid-column: 3/8;
      }
    }

    .button {
      @include minTablet {
        grid-column: 9/11;
      }
    }

    .sphere {
      @include maxMobile {
        mix-blend-mode: normal;
        color: var(--color-primary);
      }
    }
  }
  
  // contained / image
  .is-contained-image {
    @include container;

    @include minTablet {
      margin-bottom: vw(1440, 100);
    }

    .content {
      @include minTablet {
        @include grid;

        padding: vw(1440, 320) 0 vw(1440, 120);
      }
    }

    .title {
      color: var(--color-violet);

      @include minTablet {
        grid-column: 2/5;
      }
    }

    .heading {
      @include minTablet {
        @include h6;

        grid-column: 2/6;
        margin-bottom: vw(1440, 50);
      }
    }

    .button {
      @include minTablet {
        grid-column: 2/6;
      }
    }

    .sphere {
      mix-blend-mode: normal;

      @include maxMobile {
        color: var(--color-white);
      }

      @include minTablet {
        color: var(--color-violet);
      }
    }

    .plus,
    .arrow,
    .line {
      color: var(--color-soapstone);
    }
  }

  // fluid
  .is-fluid {
    .content {
      @include container;

      @include minTablet {
        @include grid;

        padding: vw(1440, 470) 0 vw(1440, 100);

        > * {
          grid-column: 1/7;
        }
      }
    }

    .title {
      color: var(--color-violet);
    }

    .heading {
      @include minTablet {
        @include h3;

        margin-bottom: vw(1440, 50);
      }
    }
  }

  // mobile
  .is-fluid,
  .is-contained-image {
    @include maxMobile {
      color: var(--color-black);
      background: var(--color-soapstone);
    }

    .content {
      @include maxMobile {
        padding: vw(375, 35) 0 vw(375, 70);
      }
    }

    .title {
      @include maxMobile {
        color: var(--color-red);
      }
    }

    .heading {
      @include maxMobile {
        @include h5;

        margin-bottom: vw(375, 40);
      }
    }

    .plus,
    .arrow,
    .line {
      @include maxMobile {
        color: var(--color-red);
      }
    }
  }
}
