.a-cursor {
  .is-device & {
    display: none;
  }
}

.cursor-preview {
  position: absolute;
  width: vw(1440, 248);
  height: vw(1440, 140);
  margin-top: vw(1440, -140);
  pointer-events: none;
  z-index: 110;
}

.preview-video {
  position: absolute;
  inset: 0;
  visibility: hidden;
  background: var(--color-secondary);
  border: 1px solid var(--color-soapstone-02);
}

.cursor-indicator,
.cursor-sphere {
  position: absolute;
  width: vw(1440, 90);
  height: vw(1440, 90);
  pointer-events: none;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: vw(1440, -65) 0 0 vw(1440, -25);
  transition: margin 200ms cubic-bezier(0, 0, 0.58, 1); /* ease-out */

  .is-link &,
  .is-image & {
    margin: vw(1440, -75) 0 0 vw(1440, -15);
  }

  .is-label & {
    margin: vw(1440, -90) 0 0 0;
  }
}

.cursor-indicator {
  z-index: 105;

  .arrow {
    width: vw(1440, 14);
    height: vw(1440, 8);
  }
}

.cursor-sphere {
  color: var(--color-white);
  mix-blend-mode: color-dodge;
  z-index: 100;

  .sphere-inside {
    position: absolute;
    inset: 0;
    border-radius: 100%;
    background: var(--color-violet);
    overflow: hidden;
  }
}

.indicator-icon {
  position: absolute;
  inset: vw(1440, 25);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  svg {
    visibility: hidden;
  }
}

.indicator-label {
  position: absolute;
  inset: vw(1440, 25);
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  visibility: hidden;

  @include title;
}
