.s-insights-nav {
  position: relative;
  width: vw(375, 283);
  height: vw(375, 46);
  display: block;
  border-radius: vw(375, 46);
  border: 1px solid var(--color-rich-black-02);
  color: var(--color-black);

  @include maxMobile {
    margin: 0 auto vw(375, 40);
  }

  @include minTablet {
    width: vw(1440, 283);
    height: vw(1440, 46);
    border-radius: vw(1440, 46);
    margin: 0 auto vw(1440, 100);
    cursor: pointer;
  }

  .active {
    @include title;

    position: absolute;
    top: vw(375, 4);
    left: vw(375, 4);
    width: vw(375, 69);
    height: vw(375, 36);
    border-radius: vw(375, 46);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-rich-black);
    background: var(--color-white);
    pointer-events: none;
    z-index: 1;

    @include minTablet {
      top: vw(1440, 4);
      left: vw(1440, 4);
      width: vw(1440, 69);
      height: vw(1440, 36);
      border-radius: vw(1440, 46);
    }
  }

  .list {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 vw(375, 4);
    justify-content: space-between;

    @include minTablet {
      margin: 0 vw(1440, 4);
    }
  }

  .item {
    @include title;

    padding: 0 vw(375, 22);

    @include minTablet {
      padding: 0 vw(1440, 22);
    }
  }
}
