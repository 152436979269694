.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: vw(375, 45);
  padding-bottom: vw(375, 10);
  color: currentColor;

  @include minTablet {
    margin-left: vw(1440, 45);
    padding-bottom: vw(1440, 10);
  }

  [data-dark] & {
    .plus,
    .arrow,
    .line {
      color: var(--color-red);
    }
  }

  .line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    overflow: hidden;

    &::before {
      transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1) 200ms;
    }

    &::after {
      transform: translateX(-101%);
      transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      will-change: transform;
      border-bottom: 1px solid currentColor;
    }
  }

  @include hover {
    &:hover {
      .line {
        &::before {
          transform: translateX(101%);
          transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &::after {
          transform: translateX(0);
          transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1) 200ms;
        }
      }
    }
  }

  @include b1;

  .label {
    position: relative;
    z-index: 1;
  }

  .sphere {
    position: absolute;
    left: vw(375, -50);
    width: vw(375, 57);
    height: vw(375, 57);
    color: var(--color-violet);
    mix-blend-mode: color-dodge;

    .header-home &,
    .s-shows-featured &,
    .s-shows-select & {
      @include maxMobile {
        mix-blend-mode: normal;
      }
    }

    @include minTablet {
      left: vw(1440, -50);
      width: vw(1440, 57);
      height: vw(1440, 57);
      pointer-events: none;
      will-change: transform;
    }
  }

  .icon {
    position: absolute;
    left: vw(375, -50);
    width: vw(375, 57);
    height: vw(375, 57);
    display: flex;
    align-items: center;
    justify-content: center;

    @include minTablet {
      left: vw(1440, -50);
      width: vw(1440, 57);
      height: vw(1440, 57);
    }
  }

  .plus {
    position: absolute;
    width: vw(375, 13);
    height: vw(375, 13);

    @include minTablet {
      width: vw(1440, 13);
      height: vw(1440, 13);
    }
  }

  .arrow {
    position: absolute;
    width: vw(375, 14);
    height: vw(375, 8);
    visibility: hidden;

    @include minTablet {
      width: vw(1440, 14);
      height: vw(1440, 8);
    }
  }
}
